import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import CardsDeck from "./CardsDeck";



const PairPopUp = ({ openPairPopUp, handleClosePairPopUp, pairProduct, handleJumpToChat }) => {
  const { pair: { productOne, productTwo }, type } = pairProduct;
 if (productOne && productTwo) {
  return (
     <Dialog
        open={openPairPopUp}
        keepMounted
        onClose={handleClosePairPopUp}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth='md'
      >
        <DialogTitle id="alert-dialog-slide-title">{`Congrats it is ${type === 'superLike'? 'Super Like Pair': 'Like Pair'}  now you can suggest swapping`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <CardsDeck character={productOne} imageGallery={productOne.imageGallery}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardsDeck character={productTwo} imageGallery={productTwo.imageGallery}/>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={()=>handleJumpToChat()} color="primary">
            Jump to chat
          </Button>
          <Button onClick={handleClosePairPopUp} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
  )
}
}

export default PairPopUp;


