import axios from 'axios';
import { API_BASE_URL } from '../../../../../common/config';

export default {
  upload: (file, dialogId) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      return axios.post(`${API_BASE_URL}/files`, formData, {
        headers: {
          dialog_id: dialogId,
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (e) {
      return e.message;
    }
  }
};
