import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import apiService from "../../../services/apiService";
import {Typography} from "@material-ui/core";
import { useTranslation } from 'react-i18next';
import Lightbox from "../../../components/Lightbox";


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 50,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 310,
    maxWidth: 500,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  imageLiteBox:{
    position: "relative"
  },
  prevIcon: {
    position: "absolute",
    opacity: '0.7',
    top: '50%',
    left: '50%',
    color: '#3f51b5',
    '&:hover': {
      cursor: "pointer",
      opacity: '1',
    },
  },
  imgFull: {
    height: '100%',
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));


const EditGallery = ({ gallery, setGallery, id }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = gallery.length;
  const { deleteImageFromGallery } = apiService;
  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onHandleDeleteImage = async (event) => {
    const index = event.currentTarget.getAttribute('step');
    const id = event.currentTarget.getAttribute('id');
    const result = await deleteImageFromGallery(id, index);
    if(result.status === 200){
      setGallery((prevGallery) => {
        const updatedGallery = [...prevGallery];
        updatedGallery.splice(index, 1);
        if(+updatedGallery.length === +activeStep){
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
        return updatedGallery;
      })
    }
  }

  return (
    <div className={classes.root}>
      <Paper square elevation={0} className={classes.header}>
        {gallery.length !== 1
          ? <Button onClick={onHandleDeleteImage} id={id} variant="contained" color="secondary" step={activeStep} startIcon={<DeleteIcon />}>
            {t('Delete image')}
            </Button>
          : <Typography color="textPrimary" variant="h5">{t('Product must has at list one image')}</Typography> }
      </Paper>
      <div className={classes.imageLiteBox}>
          <img className={classes.img} src={gallery[activeStep].imgPath} alt={gallery[activeStep].label}/>
          <Lightbox children={<img className={classes.imgFull} src={gallery[activeStep].imgPath} alt={gallery[activeStep].label}/>}/>
      </div>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            {t('Next')}
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            {t('Back')}
          </Button>
        }
      />
    </div>
  );
}
export default EditGallery;
