import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";

function Copyright() {
  return (
    <div style={{color:'#fff'}}>
      {'© '}
      <Link color="inherit" style={{color:'#fff'}} href="#">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
  },
  iconsWrapper: {
    height: 80,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    width: 28,
    height: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
  copy: {
    textAlign: 'right',
  },
}));


export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
              <p style={{color:'#fff'}}>PureSwop App</p>
          </Grid>
          <Grid item xs={12} md={6}  className={classes.copy}>
            <Copyright style={{color:'#fff'}} />
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
