import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';


const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: '#ffffff',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    fontSize: '24px',
  },
  h5: {
    textAlign: 'center',
  },
  h2: {
    marginBottom: theme.spacing(3),
    fontSize: '40px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '50px',
    },
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues(props) {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <img
          src="/static/images/productCurvyLines.png"
          className={classes.curvyLines}
          alt="curvy lines"
        />
        <Typography variant="h4" marked="center" className={classes.h2} component="h2">
          {t('Advantages')}
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src="/static/images/productValues1.svg"
                  alt="suitcase"
                />
                <Typography variant="h6" marked="center" className={classes.title}>
                  {t('Unlimited')}
                </Typography>
                <Typography variant="h5" marked="center"  className={classes.h5}>
                  {t('You can add any quantity of items')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src="/static/images/productValues3.svg"
                  alt="clock"
                />
                <Typography variant="h6" marked="center" className={classes.title}>
                  {t('Free')}
                </Typography>
                <Typography variant="h5" marked="center" className={classes.h5}>
                  {t('You pay nothing to swap with other people')}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.item}>
                <img
                  className={classes.image}
                  src="/static/images/productValues2.svg"
                  alt="graph"
                />
                <Typography variant="h6" marked="center" className={classes.title}>
                  {t('New experiences')}
                </Typography>
                <Typography variant="h5" marked="center" className={classes.h5}>
                  {t('Swap only if the pair is matched')}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
