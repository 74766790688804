import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Messages from '../../containers/Messages';
import ChatInput from '../../containers/ChatInput';
import Status from '../../containers/Status';
import Sidebar from '../../containers/Sidebar';
import { connect } from 'react-redux';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "../../../../../components/ErrorFallback";
import './Home.scss';

import dialogsActions from '../../redux/actions/dialogs';

const Home = (props) => {
  
  const { setCurrentDialogId, user, dialogs } = props;
  const location = useLocation();
  useEffect(() => {
    let cleanupFunction = false;
    if (!cleanupFunction) {
    const dialogId = location.pathname.split('dialog/')[1];
    setCurrentDialogId(dialogId);
  }
  return () => cleanupFunction = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <section className="home">
      <div className="chat">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Sidebar />
      </ErrorBoundary>
        { user && (
          <div className="chat__dialog">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
            {dialogs.removedId && location.pathname === `/app/chat/dialog${dialogs.removedId}` ? null : <Status />}
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Messages />
            </ErrorBoundary>
            <div className="chat__dialog-input">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
           {dialogs.items.length && !dialogs.removedId && location.pathname !== `/app/chat/dialog${dialogs.removedId}` ? <ChatInput /> : null}
           </ErrorBoundary>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default connect(
  ({ user, dialogs }) => ({ user: user.data, dialogs } ),
  dialogsActions,
)(Home)