/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import format from 'date-fns/format';
import isToday from 'date-fns/is_today';
import { Popover, Button, Icon } from 'antd';
import { Emoji } from 'emoji-mart';
import reactStringReplace from 'react-string-replace';
import waveSvg from '../../assets/img/wave.svg';
import pauseSvg from '../../assets/img/pause.svg';
import playSvg from '../../assets/img/play.svg';
import isAudio from '../../utils/helpers/isAudio';
import convertCurrentTime from '../../utils/helpers/convertCurrentTime';

import Time from '../Time/index';
import IconReaded from '../IconReaded/index';
import Avatar from '../Avatar/index';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "../../../../../components/ErrorFallback";
import './Message.scss';
import { useTranslation } from 'react-i18next';


const MessageAudio = ({ audioSrc }) => {
  const audioElem = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const togglePlay = () => {
    if (!isPlaying) {
      audioElem.current.play();
    } else {
      audioElem.current.pause();
    }
  };

  useEffect(() => {
    audioElem.current.volume = '1.0';
    audioElem.current.addEventListener(
      'playing',
      () => {
        setIsPlaying(true);
      },
      false,
    );
    audioElem.current.addEventListener(
      'ended',
      () => {
        setIsPlaying(false);
        setProgress(0);
        setCurrentTime(0);
      },
      false,
    );
    audioElem.current.addEventListener(
      'pause',
      () => {
        setIsPlaying(false);
      },
      false,
    );
    audioElem.current.addEventListener('timeupdate', () => {
      const duration = (audioElem.current && audioElem.current.duration) || 0;
      setCurrentTime(audioElem.current.currentTime);
      setProgress((audioElem.current.currentTime / duration) * 100);
    });
  }, []);

  return (
    <div className="message__audio">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
         <audio ref={audioElem} src={audioSrc} preload="auto" />
      </ErrorBoundary>
      <div className="message__audio-progress" style={{ width: `${progress}%` }} />
      <div className="message__audio-info">
        <div className="message__audio-btn">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <button onClick={togglePlay}>
            {isPlaying ? (
              <img src={pauseSvg} alt="Pause svg" />
            ) : (
              <img src={playSvg} alt="Play svg" />
            )}
          </button>
          </ErrorBoundary>
        </div>
        <div className="message__audio-wave">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <img src={waveSvg} alt="Wave svg" />
        </ErrorBoundary>
        </div>
        <span className="message__audio-duration">{convertCurrentTime(currentTime)}</span>
      </div>
    </div>
  );
};

const Message = ({
  avatar,
  user,
  text,
  date,
  createdAt,
  isMe,
  read,
  attachments,
  isTyping,
  onRemoveMessage,
  setPreviewImage,
}) => {
  const { t } = useTranslation();

  const getMessageTime = (data) => {
    if (isToday(data)) {
      return format(data, 'HH:mm');
    }
    return format(data, 'DD.MM.YYYY');
  };
  const renderAttachment = (item) => {
    if (item.ext !== 'webm') {
      return (
        <div
          key={item._id}
          onClick={() => setPreviewImage(item.url)}
          className="message__attachments-item"
        >
          <div className="message__attachments-item-overlay">
            <Icon type="eye" style={{ color: 'white', fontSize: 18 }} />
          </div>
          <img src={item.url} alt={item.filename} />
        </div>
      );
    }
    return <MessageAudio key={item._id} audioSrc={item.url} />;
  };

  return (
    <div
      className={classNames('message', {
        'message--isme': isMe,
        'message--is-typing': isTyping,
        'message--is-audio': isAudio(attachments),
        'message--image': !isAudio(attachments) && attachments && attachments.length === 1 && !text,
      })}
    >
      <div className="message__content">
        <IconReaded isMe={isMe} isReaded={read} />
        <Popover
          content={(
            <div>
              <Button onClick={onRemoveMessage}>{t('Delete message')}</Button>
            </div>
          )}
          trigger="click"
        >
          <div className="message__icon-actions">
            <Button type="link" shape="circle" icon="ellipsis" />
          </div>
        </Popover>
        <div className="message__avatar">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Avatar user={user} />
        </ErrorBoundary>
        </div>
        <div className="message__info">
          {(text || isTyping) && (
           <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className="message__bubble">
              {text && (
                <p className="message__text">
                  {reactStringReplace(text, /:(.+?):/g, (match, i) => (
                    <Emoji key={i} emoji={match} set="apple" size={16} />
                  ))}
                </p>
              )}
              {isTyping && (
                <div className="message__typing">
                  <span />
                  <span />
                  <span />
                </div>
              )}
              {false && <MessageAudio audioSrc={null} />}
              {createdAt && (
              <span className="message__date">
                {getMessageTime(createdAt)}
              </span>
              )}
            </div>
            </ErrorBoundary>
          )}
            {attachments && (
              <div className="message__attachments">
                {attachments.map((item) => renderAttachment(item))}
              </div>
            )}
          {date && (
            <span className="message__date">
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                 <Time date={date} />
              </ErrorBoundary>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

Message.defaultProps = {
  user: {},
};

Message.propTypes = {
  avatar: PropTypes.string,
  text: PropTypes.string,
  date: PropTypes.string,
  user: PropTypes.object,
  attachments: PropTypes.array,
  isMe: PropTypes.bool,
  isReaded: PropTypes.bool,
  isTyping: PropTypes.bool,
  audio: PropTypes.string,
};

export default Message;
