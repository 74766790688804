import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ResultNotice = ({openNotice, handleCloseNotice, severityNotice, messageNotice}) => {

  return (
    <Snackbar open={openNotice} autoHideDuration={3000} onClose={handleCloseNotice}>
      <Alert onClose={handleCloseNotice} severity={severityNotice}>
        {messageNotice}
      </Alert>
    </Snackbar>
  );
};

export default ResultNotice;
