import React, {useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from "@material-ui/core/IconButton";
import EditProductForm from "./EditProductForm";
import EditIcon from "@material-ui/icons/Edit";

const EditDialog = ({ onUpdateHandle, item }) => {
  const [open, setOpen] = React.useState(false);
  const [gallery, setGallery] = React.useState(item.imageGallery);

  useEffect(() => {
    setGallery(item.imageGallery);
  },[item]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClosePopUp = () => {
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <IconButton onClick={handleClickOpen} aria-label="edit">
        <EditIcon color="primary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <EditProductForm gallery={gallery} setGallery={setGallery} handleClosePopUp={handleClosePopUp} item={item} onUpdateHandle={onUpdateHandle}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EditDialog;
