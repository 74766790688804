/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Empty } from 'antd';
import find from 'lodash/find';

import messagesActions from '../redux/actions/messages';
import socket from '../core/socket';

import BaseMessages from '../components/Messages/index';
let prevData = '';
const Dialogs = ({
  currentDialog,
  fetchMessages,
  addMessage,
  items,
  user,
  isLoading,
  removeMessageById,
  attachments,
  currentDialogId
}) => {
  if (!currentDialog) {
    return <Empty description="Откройте диалог" />;
  }

  const [previewImage, setPreviewImage] = useState(null);
  const [blockHeight, setBlockHeight] = useState(135);
  const [isTyping, setIsTyping] = useState(false);
  let typingTimeoutId = null;

  const messagesRef = useRef(null);

  const onNewMessage = data => {
    if (data) {
      const newData = data._id;
      if (prevData !== newData) {
        addMessage(data);
        prevData = newData;
      }
    }
  };

  const toggleIsTyping = obj => {
    if (currentDialogId === obj.dialogId) {
      setIsTyping(true);
      clearInterval(typingTimeoutId);
      typingTimeoutId = setTimeout(() => {
        setIsTyping(false);
      }, 2000);
    }
  };

  useEffect(() => {
    const el = document.querySelector('.chat__dialog-header');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const el = document.querySelector('.chat__dialog-header');
    if (attachments.length) {
      setBlockHeight(el.clientHeight + 179);
    } else {
      setBlockHeight(el.clientHeight + 74);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments, user]);

  useEffect(() => {
    let cleanupFunction = false;
    if (!cleanupFunction) {
      socket.on('SERVER:NEW_MESSAGE', onNewMessage);
      socket.on('DIALOGS:TYPING', toggleIsTyping);
      if (currentDialog) {
        fetchMessages(currentDialog._id);
      }
      return () => {
        socket.removeListener('SERVER:NEW_MESSAGE', onNewMessage);
        socket.removeListener('DIALOGS:TYPING', toggleIsTyping);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    return () => (cleanupFunction = true);
  }, [currentDialog]);
  

  useEffect(() => {
    messagesRef.current.scrollTo(0, 999999);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, isTyping]);

  return (
    <BaseMessages
      user={user}
      blockRef={messagesRef}
      items={items}
      isLoading={isLoading && !user}
      onRemoveMessage={removeMessageById}
      setPreviewImage={setPreviewImage}
      previewImage={previewImage}
      blockHeight={blockHeight}
      isTyping={isTyping}
      partner={
        user._id == currentDialog.partner._id
          ? currentDialog.author
          : currentDialog.partner
      }
    />
  );
};

export default connect(
  ({ dialogs, messages, user, attachments }) => ({
    currentDialogId: dialogs.currentDialogId,
    currentDialog: find(dialogs.items, { _id: dialogs.currentDialogId }),
    items: messages.items,
    isLoading: messages.isLoading,
    attachments: attachments.items,
    user: user.data
  }),
  messagesActions
)(Dialogs);
