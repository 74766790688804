import React from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import userActions from './redux/actions/user';
import { API_BASE_URL } from '../../../common/config';

import store from './redux/store';

import './styles/index.scss';
import 'emoji-mart/css/emoji-mart.css';
import App from './App';

const Chat = () => {
  const userInfo = localStorage.getItem('userInfo');
  axios.defaults.baseURL = API_BASE_URL;
  axios.defaults.headers.common.token = window.localStorage.userToken;
  axios.defaults.headers.common.id = JSON.parse(userInfo)._id;
  window.axios = axios;
  store.dispatch(userActions.fetchUserData());
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

export default Chat;
