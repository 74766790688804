/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import classNames from 'classnames';
import format from 'date-fns/format';
import isToday from 'date-fns/is_today';
import { Link } from 'react-router-dom';
import Avatar from '../Avatar/index';
import { Button, Popover } from 'antd';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "../../../../../components/ErrorFallback";
import './DialogItem.scss';
import { useTranslation } from 'react-i18next';
import Badge from '@material-ui/core/Badge'
import { useLocation } from 'react-router-dom';
import socket from '../../core/socket';


let counter = [];
let prevData=[];

const getMessageTime = (createdAt) => {
  if (isToday(createdAt)) {
    return format(createdAt, 'HH:mm');
  }
  return format(createdAt, 'DD.MM.YYYY');
};

const renderLastMessage = (message, userId) => {
  let text = '';
  if (!message.text && message.attachments.length) {
    text = 'прикрепленный файл';
  } else {
    text = message.text;
  }

  return `${message.user._id === userId ? 'Вы: ' : ''}${text}`;
};

const DialogItem = ({
  _id,
  currentDialogId,
  author,
  partner,
  lastMessage,
  userId,
  onRemoveDialog,
  onfetchDialog
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))._id;

  useEffect(() => {
    let cleanupFunction = false;
    if (!cleanupFunction && window.innerWidth < 700) {
    const locationPatch = location.pathname.split('dialog/')[1];
    if (!locationPatch){
      const sidebar = document.getElementsByClassName('chat__sidebar');
      const chat = document.getElementsByClassName('chat__dialog');
      sidebar[0].style.display = 'block';
      chat[0].style.display = 'none';
    }
  }
  return () => cleanupFunction = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

 
  if (lastMessage && lastMessage.user._id !== userInfo) {
    const newData = lastMessage._id;
    if (prevData.filter(item => item == newData).length === 0 && lastMessage.readMessage === false) {
      counter.push(lastMessage.dialog)
      prevData.push(newData);
    }
  }

 if (_id == currentDialogId) {
  counter = counter.filter(item => item !== _id)
  }

  const handleClick = (e) => {
    if (window.innerWidth < 700 && e.target.tagName !== 'BUTTON') {
    const sidebar = document.getElementsByClassName('chat__sidebar');
    const chat = document.getElementsByClassName('chat__dialog');
    sidebar[0].style.display = 'none';
    chat[0].style.display = 'block';
    }
  };

return (
  <Link onClick={handleClick} to={`dialog/${_id}`}>
    <div onClick={onfetchDialog} className={classNames('dialogs__item', {
      'dialogs__item--online': author._id !== userId ? author.isOnline : partner.isOnline ,
      'dialogs__item--selected': currentDialogId === _id,
    })}
    >
      <div className="dialogs__item-avatar">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
         <Avatar user={author._id === userId ? partner : author} />
      </ErrorBoundary>
      </div>
      <div className="dialogs__item-info">
        <div className="dialogs__item-info-top">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <b>{author._id === userId ? partner.firstName : author.firstName}</b>
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {lastMessage ? <span>{getMessageTime(lastMessage.createdAt)}</span> : null}
        </ErrorBoundary>
        </div>
        <div className="dialogs__item-info-bottom">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {lastMessage ? <p>{renderLastMessage(lastMessage, userId)}</p> : null}
        </ErrorBoundary>
        </div>

        {/* <Badge badgeContent={counter.filter(item => item == _id).length} color="primary">
        </Badge> */}

        {counter.filter(item => item == _id).length > 0 ? <Badge color="primary" variant="dot">
        </Badge> : null}

      </div>
       <div>
    <Popover
      className="chat__dialog-header-action" position='relative !important'
      content={
        <div>
          <Button onClick={onRemoveDialog}>
            {t('Delete dialog')}
            </Button>
        </div>
      }
      trigger="click">
      <div>
        <Button type="link" shape="circle" icon="ellipsis" />
      </div>
    </Popover>
    </div>
     </div>
  </Link>
  );
  }

export default DialogItem;
