import React from 'react';
import Button from '@material-ui/core/Button';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useTranslation } from 'react-i18next';


const UploadProductGallery = ({ handleOnSaveGallery }) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleOnSave = async (files) => {
    handleOnSaveGallery(files)
    setOpen(false);
  };

  return (
    <>
      <Button color="primary" fullWidth variant="text" onClick={() => setOpen(true)}>
        {t('Upload images')}
      </Button>
      <DropzoneDialog
        acceptedFiles={['image/*']}
        cancelButtonText={t('cancel')}
        submitButtonText={t('submit')}
        dialogTitle={t('Upload file')}
        maxFileSize={5000000}
        filesLimit={8}
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleOnSave}
        showPreviews={true}
        showFileNamesInPreview={true}
      />
    </>
  )
};

export default UploadProductGallery;

