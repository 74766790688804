import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import ItemsList from './ItemsList';
import Toolbar from './Toolbar';
import apiService from "../../../services/apiService";
import ResultNotice from "../../../components/ResultNotice";
import helpers from "../../../common/helpers";
import ItemSkeleton from "../../../components/ItemSkeleton";
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "../../../components/ErrorFallback";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [openNotice, setOpenNotice] = useState(false);
  const [noticeInfo, setNoticeInfo] = useState({messageNotice: 'Success', severityNotice: 'success'});
  const { getAllUserItems, deleteUserItems } = apiService;
  const { removeUserItem } = helpers;

  useEffect(() => {
    let cleanup = false;
    const fetchData = async () => {
      setLoadingTable(true);
      const userItems = await getAllUserItems();
      if(!cleanup){
        setItems(userItems.data);
        setLoadingTable(false);
      }
    }
    fetchData();
    return () => cleanup = true;
  },[]);

  const onDeleteHandle = async (id) => {
    const result = await deleteUserItems(id);

    if(result.status !== 200){
      setNoticeInfo({messageNotice: 'Somethings went wrong', severityNotice: 'error'})
      setOpenNotice(true);
    } else {
      setNoticeInfo({messageNotice: 'Item was delete successfully', severityNotice: 'success'})
      setOpenNotice(true);
      setItems(prevState => {
        const newItems = prevState.filter(item => item._id !== id);
        removeUserItem(newItems);
        return newItems;
      })
    }
  };

  const onUpdateHandle = (updatedItem) => {
    setItems((prev) => {
      return prev.map(item => {
        if(item._id !== updatedItem._id){
          return item;
        }else {
          return updatedItem;
        }
      })
    });
  };

  const handleCloseNotice = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotice(false);
  };

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Toolbar setItems={setItems}  loading={loading} setLoading={setLoading} />
        <Box mt={3}>
          {loadingTable
            ? <>
              <ItemSkeleton/>
              <ItemSkeleton/>
              <ItemSkeleton/>
            </>
            : <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ItemsList items={items} onDeleteHandle={onDeleteHandle} loading={loading} onUpdateHandle={onUpdateHandle}/>
              </ErrorBoundary>
            }
          <ResultNotice
            openNotice={openNotice}
            handleCloseNotice={handleCloseNotice}
            severityNotice={noticeInfo.severityNotice}
            messageNotice={noticeInfo.messageNotice}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
