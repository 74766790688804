import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import dialogsActions from '../redux/actions/dialogs';
import socket from '../core/socket';
import BaseDialogs from '../components/Dialogs/index';
const Dialogs = ({
  fetchDialogs,
  removeDialogs,
  removeDialogById,
  updateReadedStatus,
  currentDialogId,
  items,
  userId,
  removedId
}) => {
  const [inputValue, setValue] = useState('');
  const [filtred, setFiltredItems] = useState(Array.from(items));
  const onChangeInput = (value = '') => {
    setFiltredItems(
      items.filter(
        dialog =>
          dialog.author.firstName.toLowerCase().indexOf(value.toLowerCase()) >=
            0 ||
          dialog.partner.firstName.toLowerCase().indexOf(value.toLowerCase()) >=
            0
      )
    );
    setValue(value);
  };

  window.fetchDialogs = fetchDialogs;

  useEffect(() => {
    let cleanupFunction = false;
    if (!cleanupFunction) {
      onChangeInput();
    }
    return () => (cleanupFunction = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    let cleanupFunction = false;
    if (!cleanupFunction) {
      fetchDialogs();
      socket.on('SERVER:DIALOG_CREATED', fetchDialogs);
      socket.on('SERVER:DIALOG_DELATED', removeDialogs);
      socket.on('SERVER:MESSAGES_READED', updateReadedStatus);
      socket.on('SERVER:NEW_MESSAGE', fetchDialogs);

      return () => {
        socket.removeListener('SERVER:DIALOG_CREATED', fetchDialogs);
        socket.removeListener('SERVER:DIALOG_DELATED', removeDialogs);
        socket.removeListener('SERVER:MESSAGES_READED', updateReadedStatus);
        socket.removeListener('SERVER:NEW_MESSAGE', fetchDialogs);
      };
    }
    return () => (cleanupFunction = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseDialogs
      userId={userId}
      items={filtred}
      onSearch={onChangeInput}
      inputValue={inputValue}
      currentDialogId={currentDialogId}
      onRemoveDialog={removeDialogById}
      removedId={removedId}
      fetchDialogs={fetchDialogs}
    />
  );
};

export default connect(({ dialogs }) => dialogs, dialogsActions)(Dialogs);
