import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import helpers from "../../../common/helpers";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestOrders = ({ className, statistic: {pairs}, ...rest }) => {
  const classes = useStyles();
  const {getProductOfPartnerUser, getPartnerUser} = helpers
  const { t } = useTranslation();

  const partnerProducts = pairs.map((item) => {
    return {
      user: getPartnerUser(item),
      products:getProductOfPartnerUser(item),
      closedDate: item.closedDate,
      status: item.pairStatus,
      _id: item._id
    }
  })
  const [changes] = useState(partnerProducts);

  return (
    <Card className={clsx(classes.root, className)}{...rest}>
      <CardHeader title={t("Latest Changings")} />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t("Image")}
                </TableCell>
                <TableCell>
                  {t("Name")}
                </TableCell>
                <TableCell>
                  {t("Date")}
                </TableCell>
                <TableCell>
                  {t("Status")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {changes.map((change) => (
                change.products !== undefined ?
                <TableRow hover key={change._id}>
                  <TableCell>
                    <Avatar variant="square"  alt="Remy Sharp" src={change.products.imageGallery[0].imgPath}/>
                  </TableCell>
                  <TableCell>
                    {change.user.firstName}
                  </TableCell>
                  <TableCell>
                    {moment(change.closedDate).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell>
                    <Chip color="primary" label={t(change.status)} size="small"/>
                  </TableCell>
                </TableRow>
                  : <TableRow hover key={change._id}>
                    <TableCell>
                      <span>{t('Product was removed')}</span>
                    </TableCell>
                    <TableCell>
                      {change.user.firstName}
                    </TableCell>
                    <TableCell>
                      {moment(change.closedDate).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Chip color="primary" label={t(change.status)} size="small"/>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
