const initialState = {
  items: [],
  currentDialogId: window.location.pathname.split('dialog/')[1],
  isLoading: false,
};

export default (state = initialState, { type, payload, deletedId }) => {
  switch (type) {
    case 'DIALOGS:SET_ITEMS':
      return {
        ...state,
        items: payload,
        removedId: null,
      };
    case 'DIALOGS:SET_CURRENT_DIALOG_ID':
      return {
        ...state,
        currentDialogId: payload,
        removedId: null,
      };
      case 'DIALOGS:REMOVE_DIALOG':
      
      return {
        ...state,
        items: state.items.filter((dialogs) => dialogs._id !== payload),
        removedId: deletedId ? deletedId.id : null,
      };
    default:
      return state;
  }
};
