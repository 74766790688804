import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import Lightbox from "../../../components/Lightbox";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 310,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 280,
    maxWidth: 400,
    overflow: 'hidden',
    display: 'block',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  imgFull: {
    height: '100%',
    overflow: 'hidden',
    display: 'block',
    width: '100%',
  },
}));

export default function ImageGallery({ gallery }) {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const { t } = useTranslation();
  const maxSteps = gallery.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <img className={classes.img} src={gallery[activeStep].imgPath} alt={gallery[activeStep].label}/>
      <Lightbox children={<img className={classes.imgFull} src={gallery[activeStep].imgPath} alt={gallery[activeStep].label}/>}/>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} onTouchEnd={handleNext} disabled={activeStep === maxSteps - 1}>
            {t('Next')}
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack}  onTouchEnd={handleBack}  disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            {t('Back')}
          </Button>
        }
      />
    </div>
  );
}
