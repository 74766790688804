/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../components/Sidebar';

const SidebarContainer = ({ user }) => {
  const [users, setUsers] = useState([]);

  return (
    <Sidebar
      user={user}
      users={users}
    />
  );
};

export default connect(({ user }) => ({ user: user.data }))(SidebarContainer);