import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles, Chip,
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import DeleteDialog from "./DeleteDialog";
import EditDialog from "./EditDialog";
import ItemSkeleton from "../../../components/ItemSkeleton";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
}));


const ItemsList = ({ className, items, onDeleteHandle, onUpdateHandle, loading, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const { t } = useTranslation();

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = items.map((item) => item._id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(1));
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(selectedCustomerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === items.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < items.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>
                  {t('Image and title')}
                </TableCell>
                <TableCell>
                  {t('Description')}
                </TableCell>
                <TableCell>
                  {t('Status')}
                </TableCell>
                <TableCell>
                  {t('Date')}
                </TableCell>
                <TableCell>
                  {t('Edit or Delete')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.slice(0, limit).map((item) => (
                <TableRow hover key={item._id} selected={selectedCustomerIds.indexOf(item._id) !== -1}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedCustomerIds.indexOf(item._id) !== -1}
                      onChange={(event) => handleSelectOne(event, item._id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Avatar className={classes.avatar} src={item.imageGallery[0].imgPath}>
                        {getInitials(item.title)}
                      </Avatar>
                      <Typography color="textPrimary" variant="body1">
                        {item.title}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {item.description}
                  </TableCell>
                  <TableCell>
                    <Chip color="primary" label={`${t(item.status)}`} size="small"/>
                  </TableCell>
                  <TableCell>
                    {moment(`${item.creationDate}`).format('LL')}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="start">
                      <EditDialog editkey={item._id} item={item} onUpdateHandle={onUpdateHandle}/>
                      <DeleteDialog deletkey={item._id} title={item.title} onDeleteHandle={onDeleteHandle}/>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {loading ?  <ItemSkeleton/>  : ''}
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={items.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

ItemsList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired
};

export default ItemsList;
