import React from 'react';
import { withStyles, makeStyles, useTheme  } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ZoomInIcon from "@material-ui/icons/ZoomIn";

const useStyles = makeStyles((theme) => ({
  prevIcon:{
    color: '#3f51b5',
    fontSize: '30px',
    '&:hover': {
      cursor: "pointer",
      opacity: '1',
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


export default function Lightbox(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { children } = props;
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.button}>
        <ZoomInIcon onClick={handleClickOpen} onTouchEnd={handleClickOpen} className={classes.prevIcon}/>
      </div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} scroll='body' >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Full image
        </DialogTitle>
        <DialogContent dividers>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}
