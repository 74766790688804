import jwt_decode from "jwt-decode";

const helpers = {
  tokenDecode: () => {
    const userToken = localStorage.getItem('userToken');
    return jwt_decode(userToken);
  },
  setToken: (token) => {
    localStorage.setItem('userToken', token);
  },

  setUserInfo: (info) => {
    const userInfo = JSON.stringify(info);
    localStorage.setItem('userInfo', userInfo);
  },

  setUserItem: (itemId) => {
    const userInfo = helpers.getUserInfo();
    const { products } = userInfo;

    const newUserInfo = {
      ...userInfo,
      products: [...products, itemId]
    }
    localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
  },

  removeUserItem: (items) => {
    const userInfo = helpers.getUserInfo();
    const newUserInfo = {
      ...userInfo,
      products: items.map(userItem => userItem._id)
    }
    localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
  },

  getUserInfo: () => {
    const userInfo = localStorage.getItem('userInfo');
    return JSON.parse(userInfo);
  },

  setUserInfoFromToken: (token) => {
    const decodedToken = helpers.tokenDecode(token);
    const userInfo = {
      _id: decodedToken._id,
      firstName: decodedToken.firstName,
      role: decodedToken.role,
      email: decodedToken.email,
      avatar: decodedToken.avatar,
      country: decodedToken.country,
      city: decodedToken.city,
      lastName: decodedToken.lastName,
      interestingCategory: decodedToken.interestingCategory,
      phone: decodedToken.phone,
      products: decodedToken.products,
      settings: decodedToken.settings,
      liked:decodedToken.liked,
      disliked:decodedToken.disliked,
      superLiked:decodedToken.superLiked,
      lang:decodedToken.lang,
  };
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  },

  getProfileCompleat: (values) => {
    const {
      avatar,
      city,
      country,
      firstName,
      interestingCategory,
      lastName,
      products,
      phone,
    } = values

    const params = [
      avatar,
      city,
      country,
      firstName,
      interestingCategory,
      lastName,
      phone,
    ]
    let complete = 0;
    params.forEach((item) => {
      if(Array.isArray(item)){
        if(item.length  > 0){
          complete++
        }
      } else {
        if(item !== ''){
          complete++
        }
      }
    })
    return Math.floor((complete / params.length) * 100);
  },
  getProductOfCurrentUser: (pair) => {
    const { _id } = helpers.getUserInfo();
    const {productOne, productTwo} = pair;
    if (productOne && productOne.user === _id){
      return productOne;
    }
    if (productTwo && productTwo.user === _id){
      return productTwo;
    }
  },
  getProductGalleryOfCurrentUser: (pair) => {
    const { _id } = helpers.getUserInfo();
    const {productOne, productTwo} = pair;
    if (productOne && productOne.user === _id){
      return productOne.imageGallery;
    }
    if (productTwo && productTwo.user === _id){
      return productTwo.imageGallery;
    }
  },

  getProductOfPartnerUser: (pair) => {
    const { _id } = helpers.getUserInfo();
    const {productOne, productTwo} = pair;
    if (productOne && productOne.user !== _id){
      return productOne;
    }
    if (productTwo && productTwo.user !== _id){
      return productTwo;
    }
  },
  getPartnerUser: (pair) => {
    const { _id } = helpers.getUserInfo();
    const {userOne, userTwo} = pair;
    if (userOne && userOne._id !== _id){
      return userOne;
    }
    if (userTwo && userTwo._id !== _id){
      return userTwo;
    }
  },

  getCurrentUser: (pair) => {
    const { _id } = helpers.getUserInfo();
    const {userOne, userTwo} = pair;
    if (userOne && userOne._id === _id){
      return userOne;
    }
    if (userTwo && userTwo._id === _id){
      return userTwo;
    }
  },

  getProductGalleryOfPartnerUser: (pair) => {
    const { _id } = helpers.getUserInfo();
    const {productOne, productTwo} = pair;
    if (productOne && productOne.user !== _id){
      return productOne.imageGallery;
    }
    if (productTwo && productTwo.user !== _id){
      return productTwo.imageGallery;
    }
  },

  getUserFromProduct: (product) => {
    if (product && product.user){
      return product.user;
    }
  },

  createStatistic: ({user, pairs, lastLiked}) => {
    const { disliked, liked, superLiked } = user;
    const latestLikedProducts = lastLiked.map((item)=>{
      return  {
        _id: item._id,
        name: item.title,
        imageUrl: item.imageGallery[0].imgPath,
        desc: item.description,
      }
    });
    const statistics = {
      latestLikedProducts,
      dislikes: disliked.length,
      likes: liked.length,
      superLikes: superLiked.length,
      totalChanging: pairs.filter((item)=> item.pairStatus === 'closed').length,
      pairs: pairs.filter((item)=> item.pairStatus === 'closed').reverse().slice(0,5),
    }
    return statistics
  }

};
export default helpers;
