import React, {useState} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import apiService from "../../services/apiService";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const RegisterView = (props) => {
  let timeOut = null;
  const [open, setOpen] = useState(false);
  const [openSuccessNotice, setOpenSuccessNotice] = useState(false);
  const [notice, setNotice] = useState({message: '', severity: 'error'});
  const [success, setSuccessNotice] = useState({message: 'Now you can Log in', severity: 'success'});
  const navigate = useNavigate();
  const { handleCloseForm } = props;
  const { t } = useTranslation();

  const onHandleSubmit = async (values, setSubmitting) => {
    const { registration } = apiService;
    const userInfo = {
      ...values,
      language: /^ru\b/.test(navigator.language) ? 'ru' : 'en'
    }
    const result = await registration(userInfo);
    if (result.status === 200) {
      setSuccessNotice({message:   t('Now you can Log in'), severity: 'success'});
      setOpenSuccessNotice(true);
      timeOut = setTimeout(() => {
        if(typeof handleCloseForm === 'function' ){
          handleCloseForm();
        }
        setSubmitting(false)
        navigate('/login/', { replace: true });
      }, 2000)
    } else {
      setNotice({message: t('Somethings went wrong'), severity: 'error'});
      setOpen(true);
      timeOut = setTimeout(() => {
        if(typeof handleCloseForm === 'function' ){
          handleCloseForm();
        }
        setSubmitting(false);
        navigate('/registration/', { replace: true });
      }, 2000)
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    clearTimeout(timeOut);
  };

  return (
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              firstName: '',
              password: '',
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                firstName: Yup.string().max(255).required('First name is required'),
                password: Yup.string().max(255).required('password is required'),
              })
            }
            onSubmit={async (values, { setSubmitting }) => {
              await onHandleSubmit(values, setSubmitting);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    {t('Create new account')}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label={t("First name")}
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t("Email Address")}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t("Password")}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} >
                  <Alert onClose={handleClose} severity={notice.severity}>
                    {notice.message}
                  </Alert>
                </Snackbar>
                <Snackbar open={openSuccessNotice} autoHideDuration={3000} onClose={handleClose}>
                  <Alert onClose={handleClose} severity={success.severity}>
                    {success.message}
                  </Alert>
                </Snackbar>
                <Box my={2}>
                  <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                    {t("Sign up")}
                  </Button>
                </Box>
                <Typography color="textSecondary" variant="body1">
                  {t("Have an account?")}
                  {' '}
                  <Link component={RouterLink} to="/login" onClick={handleCloseForm} variant="h6">
                    {t("Sign in")}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
  );
};

export default RegisterView;
