/* eslint-disable react/prop-types */
import React from 'react';
import {
  Select
} from 'antd';
import Dialogs from '../../containers/Dialogs';

import './Sidebar.scss';

const { Option } = Select;

const Sidebar = ({
  user,
  users,
}) => {
  const options = users.map((user) => <Option key={user._id}>{user.login}</Option>);
  return (
    <div className="chat__sidebar">
      <div className="chat__sidebar-dialogs" >
        <Dialogs userId={user && user._id} />
      </div>
    </div>
  );
};

Sidebar.defaultProps = {
  users: [],
};

export default Sidebar;
