import axios from 'axios';
import { API_BASE_URL } from '../common/config';
import helpers from '../common/helpers';

const apiService = {

  // Login and Registration

  registration: async (values) => {
    try {
      const dataApi = await axios.post(`${API_BASE_URL}/registration`, values);
      if (dataApi.status === 200) {
        return dataApi;
      }
    } catch (e) {
      return e.message;
    }
  },

  login: async (values) => {
    try {
      const dataApi = await axios.post(`${API_BASE_URL}/login`, values);
      if (dataApi.status === 200) {
        return dataApi;
      }
    } catch (e) {
      return e.message;
    }
  },

  // User Profile

  uploadAvatar: async (avatar) => {
    try {
      const data = new FormData();
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();
      data.append('avatar', avatar[0]);
      data.append('_id', _id);
      const updatedUser = await axios.put(`${API_BASE_URL}/profile`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return updatedUser;
    } catch (e) {
      return e.message;
    }
  },

  updateUserProfile: async (dataProfile) => {
    try {
      const { getUserInfo } = helpers;
      const userData = getUserInfo();
      const updateData = {
        ...userData,
        ...dataProfile,
        avatar: userData.avatar
      };
      const updatedUser = await axios.patch(`${API_BASE_URL}/profile`, updateData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return updatedUser;
    } catch (e) {
      return e.message;
    }
  },

  updateUserSettings: async (userInfo) => {
    try {
      const updatedUser = await axios.patch(`${API_BASE_URL}/profile/${userInfo._id}/settings`, userInfo.settings, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return updatedUser;
    } catch (e) {
      return e.message;
    }
  },

  updateUserLanguage: async (language) => {
    const { getUserInfo } = helpers;
    const userData = getUserInfo();
    try {
      const updatedUser = await axios.patch(`${API_BASE_URL}/profile/${userData._id}/language`, {lang: language}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return updatedUser;
    } catch (e) {
      return e.message;
    }
  },

  updateUserPassword: async (userPassword) => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();
      const updatedUser = await axios.patch(`${API_BASE_URL}/profile/${_id}/password`, userPassword, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return updatedUser;
    } catch (e) {
      return e.message;
    }
  },


  // Items
  getAllUserItems: async () => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();
      const items = await axios.get(`${API_BASE_URL}/items/${_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return items;
    } catch (e) {
      return e.message;
    }
  },

  createUserItem: async (values, imageGallery) => {
    const { getUserInfo } = helpers;
    const { _id } = getUserInfo();
    const {
      category, description, status, title
    } = values;

    try {
      const data = new FormData();
      for (let i = 0; imageGallery.length > i; i++) {
        data.append('imageGallery', imageGallery[i]);
      }
      data.append('category', category);
      data.append('description', description);
      data.append('status', status);
      data.append('title', title);
      data.append('_id', _id);
      const newItem = await axios.post(`${API_BASE_URL}/items`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return newItem;
    } catch (e) {
      return e.message;
    }
  },

  deleteUserItems: async (id) => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();
      const result = await axios.delete(`${API_BASE_URL}/items/${id}/user/${_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      console.log('deleteUserItems', result)
      return result;
    } catch (e) {
      return e.message;
    }
  },

  updateUserItem: async (values, imageGallery, productId) => {
    const { getUserInfo } = helpers;
    const { _id } = getUserInfo();
    const {
      category, description, status, title
    } = values;
    try {
      const data = new FormData();
      for (let i = 0; imageGallery.length > i; i++) {
        data.append('imageGallery', imageGallery[i]);
      }
      data.append('category', category);
      data.append('description', description);
      data.append('status', status);
      data.append('title', title);
      const updatedItem = await axios.put(`${API_BASE_URL}/items/${productId}/user/${_id}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return updatedItem;
    } catch (e) {
      return e.message;
    }
  },

  deleteImageFromGallery: async (productId, index) => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();
      const result = await axios.patch(`${API_BASE_URL}/items/${productId}/user/${_id}`, { index }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return result;
    } catch (e) {
      return e.message;
    }
  },


  // Main

  getMainProducts: async () => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();

      const products = await axios.get(`${API_BASE_URL}/main/${_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return products;
    } catch (e) {
      return e.message;
    }
  },

  likeProduct: async (productId, ownerId) => {
    try {
      const { getUserInfo } = helpers;
      const { _id, products } = getUserInfo();
      const result = await axios.patch(`${API_BASE_URL}/main/${_id}/like`, { productId, ownerId, products  }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return result;
    } catch (e) {
      return e.message;
    }
  },

  superLikeProduct: async (productId, ownerId) => {
    try {
      const { getUserInfo } = helpers;
      const { _id, products } = getUserInfo();
      const result = await axios.patch(`${API_BASE_URL}/main/${_id}/super`, { productId, ownerId, products  }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return result;
    } catch (e) {
      return e.message;
    }
  },

  dislikeProduct: async (productId, ownerId) => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();
      const updatedUser = await axios.patch(`${API_BASE_URL}/main/${_id}/dislike`, { productId, ownerId  }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return updatedUser;
    } catch (e) {
      return e.message;
    }
  },


  // Pairs
  getUserPairs: async () => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();

      const pairs = await axios.get(`${API_BASE_URL}/pairs/${_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return pairs;
    } catch (e) {
      return e.message;
    }
  },

  getUserActivePairs: async () => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();

      const pairs = await axios.get(`${API_BASE_URL}/pairs/${_id}/active`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return pairs;
    } catch (e) {
      return e.message;
    }
  },
  getUserClosedPairs: async () => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();

      const pairs = await axios.get(`${API_BASE_URL}/pairs/${_id}/closed`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return pairs;
    } catch (e) {
      return e.message;
    }
  },

  closeUserPair: async (id) => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();
      const pairs = await axios.put(`${API_BASE_URL}/pairs/${_id}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return pairs;
    } catch (e) {
      return e.message;
    }
  },

  deleteUserPair: async (id) => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();
      const pairs = await axios.delete(`${API_BASE_URL}/pairs/${id}/${_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return pairs;
    } catch (e) {
      return e.message;
    }
  },

  deleteUserClosedPair: async (id) => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();
      const pairs = await axios.delete(`${API_BASE_URL}/pairs/closed/${id}/${_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return pairs;
    } catch (e) {
      return e.message;
    }
  },

   // Statistic

  getStatistics: async () => {
    try {
      const { getUserInfo } = helpers;
      const { _id } = getUserInfo();

      const result = await axios.get(`${API_BASE_URL}/statistic/${_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`
        }
      });
      return result;
    } catch (e) {
      return e.message;
    }
  },

  // Admin Access

  // createProduct: async (values, imageGallery) => {
  //   const { getUserInfo } = helpers;
  //   const { _id } = getUserInfo();
  //   const {
  //     category, description, status, title
  //   } = values;
  //
  //   try {
  //     const data = new FormData();
  //     for (let i = 0; imageGallery.length > i; i++) {
  //       data.append('imageGallery', imageGallery[i]);
  //     }
  //     data.append('category', category);
  //     data.append('description', description);
  //     data.append('status', status);
  //     data.append('title', title);
  //     data.append('_id', _id);
  //     const newProduct = await axios.post(`${API_BASE_URL}/products`, data, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('userToken')}`
  //       }
  //     });
  //     return newProduct;
  //   } catch (e) {
  //     return e.message;
  //   }
  // },

  // getProducts: async () => {
  //   try {
  //     const products = await axios.get(`${API_BASE_URL}/products`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem('userToken')}`
  //       }
  //     });
  //     return products;
  //   } catch (e) {
  //     return e.message;
  //   }
  // },

  //
  // getCurrentUser: async () => {
  //
  // },
  //
  // getStatistics: () => {
  //
  // },

};

export default apiService;
