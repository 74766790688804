import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  ShoppingBag as ShoppingBagIcon
} from 'react-feather';
import NavItem from './NavItem';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import BallotIcon from '@material-ui/icons/Ballot';
import ForumIcon from '@material-ui/icons/Forum';
import ProgressLine from '../../../components/ProgressLine';
import helpers from '../../../common/helpers';
import UserInfoContext from '../../../common/userInfoContext';
import socket from '../../../views/Chat/ChatView/core/socket';
import Badge from '@material-ui/core/Badge';
import { useTranslation } from 'react-i18next';

const items = [
  {
    href: '/app/main',
    icon: TouchAppIcon,
    title: 'Main'
  },
  {
    href: '/app/statistics',
    icon: BarChartIcon,
    title: 'Statistic'
  },
  {
    href: '/app/items',
    icon: BallotIcon,
    title: 'Items'
  },
  {
    href: '/app/chat',
    icon: ForumIcon,
    title: 'Chat'
  },
  {
    href: '/app/pairs',
    icon: ShoppingBagIcon,
    title: 'Pairs'
  },
  {
    href: '/app/closed-pairs',
    icon: AlertCircleIcon,
    title: 'Closed Pairs'
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile, getDialogInformation, setUnreading, unreading }) => {
  const classes = useStyles();
  const location = useLocation();
  const { getProfileCompleat } = helpers;
  const [userInfo] = useContext(UserInfoContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        {userInfo.avatar ? (
          <Avatar
            className={classes.avatar}
            component={RouterLink}
            src={userInfo.avatar}
            to="/app/account"
          />
        ) : (
          <Avatar
            className={classes.avatar}
            component={RouterLink}
            to="/app/account"
          />
        )}
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {userInfo.firstName || userInfo.login}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {t('Profile complete')}
        </Typography>
        <ProgressLine compleat={getProfileCompleat(userInfo)} />
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => (
            <NavItem
              href={item.href}
              key={item.title}
              title= {t(item.title)}
              icon={item.icon}
              getDialogInformation={getDialogInformation}
            />
          ))}
          {unreading ? (
            <Badge
              style={{ left: '70px', bottom: '116px' }}
              color="primary"
              variant="dot"
            >
              {' '}
            </Badge>
          ) : null}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;

