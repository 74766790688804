import axios from 'axios';
import { API_BASE_URL } from '../../../../../common/config';

export default {
  getAll: () => axios.get(`${API_BASE_URL}/dialogs`),
  removeById: id => axios.delete(`${API_BASE_URL}/dialogs?id=${id}`),
  create: ({ author, partner, productOne, productTwo }) =>
    axios.post(`${API_BASE_URL}/dialogs`, {
      author,
      partner,
      productOne,
      productTwo
    }, 
    {
      headers: {
        token: `Bearer ${localStorage.getItem('userToken')}`
      }
    })
};
