/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles,
  useTheme
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { PRODUCT_CATEGORIES } from '../../../common/config';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ProfileDetails = ({
  className, userInfo, handleOnSaveProfileDetail, ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [values, setValues] = useState(userInfo);
  const [categories, setCategories] = useState(userInfo.interestingCategory);
  const { t } = useTranslation();

  const handleChangeSelect = (event) => {
    setCategories(event.target.value);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleOnSave = () => {
    const profileData = {
      ...values,
      interestingCategory: categories,
    };
    handleOnSaveProfileDetail(profileData);
  };

  return (
    <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest}>
      <Card>
        <CardHeader subheader={t("The information can be edited")} title={t("Profile")} />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t("First name")}
                name="firstName"
                onChange={handleChange}
                required
                value={values.firstName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t("Last name")}
                name="lastName"
                onChange={handleChange}
                required
                value={values.lastName || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                disabled={true}
                label={t("Email Address")}
                name="email"
                onChange={handleChange}
                required
                value={values.email || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t("Phone Number")}
                name="phone"
                onChange={handleChange}
                type="number"
                value={values.phone || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t("Country")}
                name="country"
                onChange={handleChange}
                required
                value={values.country || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label={t("City")}
                name="city"
                onChange={handleChange}
                required
                value={values.city || ''}
                variant="outlined"
              />
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-mutiple-chip-label">{t("Categories")}</InputLabel>
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="demo-mutiple-chip"
                  multiple
                  value={categories}
                  onChange={handleChangeSelect}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip key={value} label={t(value)} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {PRODUCT_CATEGORIES.map((category) => (
                    <MenuItem key={category} value={category} style={getStyles(category, categories, theme)}>
                      {t(category)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" onClick={handleOnSave}>
            {t('Save details')}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default ProfileDetails;
