import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import dialogsApi from '../../views/Chat/ChatView/utils/api/dialogs';
import axios from 'axios';
import { API_BASE_URL } from '../../common/config';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [unreading, setUnreading] = useState(false);

  const getDialogInformation = () => {
    const userInfo = localStorage.getItem('userInfo');
    axios.defaults.baseURL = API_BASE_URL;
    axios.defaults.headers.common.token = window.localStorage.userToken;
    axios.defaults.headers.common.id = JSON.parse(userInfo)._id;
    window.axios = axios;
    dialogsApi.getAll().then(({ data }) => {
      data.filter(
        item =>
          item.lastMessage &&
          item.lastMessage.user.id !== JSON.parse(userInfo)._id &&
          item.lastMessage.read === false
      ).length > 0
        ? setUnreading(true)
        : setUnreading(false);
    });
  };

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        getDialogInformation={getDialogInformation}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        setUnreading={data => setUnreading(data)}
        unreading={unreading}
        getDialogInformation={getDialogInformation}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
