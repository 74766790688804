import React, { useState } from 'react';
import PropTypes from 'prop-types';
import apiService from "../../../services/apiService";
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(({
  root: {}
}));

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  const { updateUserPassword } = apiService;
  const [values, setValues] = useState({
    password: '',
    confirm: ''
  });
  const [open, setOpen] = useState(false);
  const [notice, setNotice] = useState({message: '', severity: 'error'});
  const { t } = useTranslation();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSavePassword = async () => {
    if (values.password !== values.confirm) {
      setNotice({message: 'Check confirm password', severity: 'error'});
      setOpen(true);
    } else {
      const result = await updateUserPassword(values);
      if(result.status === 200){
        setNotice({message: 'Password was changed', severity: 'success'});
        setOpen(true);
      }else {
        setNotice({message: 'Somethings went wrong', severity: 'error'});
        setOpen(true);
      }
    }

  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <form className={clsx(classes.root, className)}{...rest}>
      <Card>
        <CardHeader subheader={t("Update password")} title={t("Password")}/>
        <Divider />
        <CardContent>
          <TextField fullWidth label={t("Password")} margin="normal" name="password" onChange={handleChange} type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField fullWidth label={t("Confirm password")} margin="normal" name="confirm" onChange={handleChange}
            type="password"
            value={values.confirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={onSavePassword} color="primary" variant="contained">
            {t("Update")}
          </Button>
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={notice.severity}>
            {notice.message}
          </Alert>
        </Snackbar>
      </Card>
    </form>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
