import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(({
  root: {
    height: '100%'
  },
  image: {
    height: 48,
    width: 48
  }
}));

const LatestProducts = ({ className, statistic: {latestLikedProducts}, ...rest }) => {
  const classes = useStyles();
  const [products] = useState(latestLikedProducts);
  const { t } = useTranslation();

  return (
    <Card className={clsx(classes.root, className)}{...rest}>
      <CardHeader subtitle={`${products.length} in total`} title= {t("Latest Likes")}/>
      <Divider />
      <List>
        {products.map((product, i) => (
          <ListItem divider={i < products.length - 1} key={product._id}>
            <ListItemAvatar>
              <img alt="Product" className={classes.image} src={product.imageUrl}/>
            </ListItemAvatar>
            <ListItemText primary={product.name} secondary={`${product.desc}`}/>
          </ListItem>
        ))}
      </List>
    </Card>
  );
};

LatestProducts.propTypes = {
  className: PropTypes.string
};

export default LatestProducts;
