/* eslint-disable react/prop-types */
import React, {useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import { Input, Empty } from 'antd';
import DialogItem from '../DialogItem/index';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "../../../../../components/ErrorFallback";
import './Dialogs.scss';

const Dialogs = ({
  items, userId, onSearch, inputValue, currentDialogId, onRemoveDialog, fetchDialogs, removedId
}) => {
return (
  <div className="dialogs">
  <div className="dialogs__search" >
       <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Input.Search
        placeholder="Поиск среди контактов"
        onChange={(e) => onSearch(e.target.value)}
        value={inputValue}
      />
      </ErrorBoundary>
    </div>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
    {items.length ? (
      orderBy(items, ['created_at'], ['desc']).map((item) => (
        <DialogItem
          key={item._id}
          isMe={item.author._id === userId}
          userId={userId}
          currentDialogId={currentDialogId}
          onRemoveDialog={onRemoveDialog.bind(this, item._id)}
          onfetchDialog={fetchDialogs}
          {...item}
        />
      ))
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Ничего не найдено"
      />
    )}
    </ErrorBoundary>
  </div>
);
}
export default Dialogs;
