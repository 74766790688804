import React from 'react';
import { connect } from 'react-redux';

import Home from './pages/Home/index';

const App = (props) => {
  return (
    <div className="wrapper">
      <Home />
    </div>
  );
};

export default connect(({ user }) => ({ isAuth: user.isAuth }))(App);
