import React from 'react';
import TinderCard from 'react-tinder-card';
import CardsDeck from './CardsDeck';
import { useTranslation } from 'react-i18next';
import './cards.css';

const Tinder = ({
  hanlLike,
  handleSuperLike,
  handleDisLike, characters, setCharacters, setButtonsDisable,
}) => {
  const { t } = useTranslation();

  const swiped = (direction, id, user) => {
    switch (direction) {
      case 'left':
        return handleDisLike(direction, 'swipe', id, user);
      case 'right':
        return hanlLike(direction, 'swipe', id, user);
      case 'up':
        return handleSuperLike(direction, 'swipe', id, user);
      default:
        return null;
    }
  };

  const outOfFrame = () => {
    setCharacters((prevCharacters) => prevCharacters.splice(0, prevCharacters.length - 1) || []);
    setButtonsDisable(false);
  }

  return (
    <div className="tindersin">
      {characters.length !== 0
        ? characters.map((character) => (
          <TinderCard
            className="swipe"
            key={character._id}
            user={character.user}
            onSwipe={(dir) => swiped(dir, character._id, character.user)}
            onCardLeftScreen={(der) => outOfFrame(character._id, der)}
            preventSwipe={['down']}
          >
            <CardsDeck character={character} imageGallery={character.imageGallery} />
          </TinderCard>
        ))
        : <h2 className="infoText">{t('Nothing to show')}</h2> }
    </div>
  );
};

export default Tinder;
