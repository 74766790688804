import React, {useEffect} from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Dislikes from './Dislikes';
import LatestOrders from './LatestOrders';
import LatestProducts from './LatestProducts';
import Superlikes from './Superlikes';
import Likes from './Likes';
import TotalChanging from './TotalChanging';
import apiService from "../../../services/apiService";
import helpers from "../../../common/helpers";
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "../../../components/ErrorFallback";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const [statistic, setStatistic] = React.useState(null)
  const {createStatistic} = helpers;
  const {getStatistics} = apiService;

  useEffect( () => {
    let cleanup = false;
    const fetchData = async () => {
      const response = await getStatistics();
      if (response.status === 200 ) {
        if(!cleanup){
          setStatistic(createStatistic(response.data));
        }
      } else {
        console.log(response)
      }
    };
    fetchData();
    return () => cleanup = true;
  },[])

  return (
    <Page className={classes.root} title="Statistic">
      <Container maxWidth={false}>
        {statistic
          ?<Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Dislikes statistic={statistic}/>
              </ErrorBoundary>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Likes statistic={statistic}/>
              </ErrorBoundary>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <Superlikes  statistic={statistic}/>
              </ErrorBoundary>
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <TotalChanging  statistic={statistic}/>
              </ErrorBoundary>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <LatestProducts  statistic={statistic} />
              </ErrorBoundary>
            </Grid>
            <Grid item lg={8} md={12} xl={9} xs={12}>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <LatestOrders statistic={statistic} />
              </ErrorBoundary>
            </Grid>
          </Grid>
          :''
        }

      </Container>
    </Page>
  );
};

export default Dashboard;
