const initialState = {
  items: [],
  isLoading: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'MESSAGES:ADD_MESSAGE':
      return {
        ...state,
        items: [...state.items, payload]
      };
    case 'MESSAGES:SET_ITEMS':
      if (
        payload.length >= 2 &&
        payload[payload.length - 2].read === true &&
        payload.filter(item => item.user.isOnline === false).length === 0
      ) {
        payload[payload.length - 1].read = true;
      }
      return {
        ...state,
        items: payload,
        isLoading: false
      };
    case 'DIALOGS:LAST_MESSAGE_READED_STATUS':
      return {
        ...state,
        items: state.items.map(message => {
          if (
            message.dialog._id === payload.dialogId &&
            payload.userId !== JSON.parse(localStorage.getItem('userInfo'))._id
          ) {
            message.read = true;
          }
          return message;
        })
      };
    case 'MESSAGES:REMOVE_MESSAGE':
      return {
        ...state,
        items: state.items.filter(message => message._id !== payload)
      };
    case 'MESSAGES:SET_IS_LOADING':
      return {
        ...state,
        isLoading: payload
      };
    default:
      return state;
  }
};
