import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'antd';
import { Modal } from 'antd';
import ArrowBlackIcon from '@material-ui/icons/ArrowBack';
import './Status.scss';
import { useTranslation } from 'react-i18next';

const Status = ({ online, login, productOne, productTwo, removedId }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const { t } = useTranslation();

  const handleClick = e => {
    if (window.innerWidth < 700) {
      const sidebar = document.getElementsByClassName('chat__sidebar');
      const chat = document.getElementsByClassName('chat__dialog');
      sidebar[0].style.display = 'block';
      chat[0].style.display = 'none';
    }
  };
  return (
    <div className="chat__dialog-header">
    
      <div onClick={handleClick} className="arrow-left">
        <ArrowBlackIcon style={{ color: '#3f51b5', fontSize: 23 }}>
          {' '}
        </ArrowBlackIcon>
      </div>

      <div className="chat__dialog-header">
      {removedId
        ? null
        : productOne.map((item, index) => (
            <div
              onClick={() => setPreviewImage(item)}
              className="message__attachments-item"
              key={index}
            >
              <div className="message__attachments-item-overlay">
                <Icon type="eye" style={{ color: 'white', fontSize: 18 }} />
              </div>
              <img key={index} src={item} alt={'productOne'} />
            </div>
          ))}
      </div>

      <div className="chat__dialog-header">
        <div className="chat__dialog-header-center">
          <b className="chat__dialog-header-username">{login}</b>
          <div className="chat__dialog-header-status">
            <span className={classNames('status', { 'status--online': online })}>
              {online ? t('online') : t('offline')}
            </span>
          </div>
        </div>
      </div>

      <div className="chat__dialog-header">
      {removedId
        ? null
        : productTwo.map((item, index) => (
            <div
              key={index}
              onClick={() => setPreviewImage(item)}
              className="message__attachments-item"
            >
              <div className="message__attachments-item-overlay">
                <Icon type="eye" style={{ color: 'white', fontSize: 18 }} />
              </div>
              <img src={item} alt={'productTwo'} />
            </div>
          ))}
        </div>
        
      <Modal
        visible={!!previewImage}
        onCancel={() => setPreviewImage(null)}
        footer={null}
      >
        <img src={previewImage} style={{ width: '100%' }} alt="Preview" />
      </Modal>
    </div>
  );
};

Status.propTypes = {
  online: PropTypes.bool
};

export default Status;
