import React from 'react';
import {Box, Container, Grid} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from "../views/Chat/ChatView/components/Button";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const ErrorFallback = ({error, resetErrorBoundary}) => {
  const classes = useStyles();

  const refreshPage = () => {
    window.location.reload(false);
    resetErrorBoundary(error);
  }
  const { t } = useTranslation();
  return (
    <Container maxWidth={false}>
      <Box mt={3}>
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <div className={classes.root}>
                <Card>
                  <div role="error">
                    <Alert
                      severity="error"
                      action={<Button onClick={refreshPage} color="inherit" size="small">{t("Try again")}</Button>
                      }>
                      <AlertTitle>{t("Something went wrong")}</AlertTitle>
                       <strong>{t("We sent our master to fix it")}</strong>
                    </Alert>
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>
      </Box>
    </Container>
  )
}
export default ErrorFallback;
// const ui = (
//   <ErrorBoundary
//     FallbackComponent={ErrorFallback}
//     onReset={() => {
//       // reset the state of your app so the error doesn't happen again
//     }}
//   >
//     <ComponentThatMayError />
//   </ErrorBoundary>
// )

