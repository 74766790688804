import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ProductHeroLayout from './ProductHeroLayout';
import {IMAGES_URL} from "../../../common/config";
import { useTranslation } from 'react-i18next';


const backgroundImage = `${IMAGES_URL}main-banner.jpg`;

const styles = (theme) => ({
  background: {
    backgroundImage: `url('${backgroundImage}')`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  buttonReg: {
    minWidth: 200,
    textTransform: 'uppercase',
    fontSize: '18px',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      fontSize: '2rem',
    },
    color: '#cac9c9',
    fontSize: '1rem',
    fontWeight: '400',
  },
  h2: {
    color: '#fff',
    fontSize: '2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '5rem',
    },
    fontFamily: 'Georgia',
    textTransform: 'capitalize',
  },
  more: {
    marginTop: theme.spacing(5),
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#fff',
  },
});

function ProductHero(props) {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <Typography color="inherit" align="center" variant="h2" marked="center" className={classes.h2}>
        {t('Swap anything')}
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
        {t('Traditional items')}<p>{t('to online world')}</p>
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        className={classes.buttonReg}
        component="a"
        href="/registration/"
      >
        {t('Registration')}
      </Button>
      <a href="#categories" className={classes.more}>
        {t('Discover Our Categories')}
      </a>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductHero);
