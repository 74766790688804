import dialogsApi from '../../utils/api/dialogs';
import socket from '../../core/socket';

const Actions = {
  setDialogs: (items) => ({
    type: 'DIALOGS:SET_ITEMS',
    payload: items,
  }),
  updateReadedStatus: ({ userId, dialogId }) => ( 
    {type: 'DIALOGS:LAST_MESSAGE_READED_STATUS',
    payload: {
      userId,
      dialogId,
    },
  }),
  setCurrentDialogId: (id) => (dispatch) => {
    socket.emit('DIALOGS:JOIN', id);
    dispatch({
      type: 'DIALOGS:SET_CURRENT_DIALOG_ID',
      payload: id,
    });
  },

  fetchDialogs: () => (dispatch) => {
    dialogsApi.getAll().then(({ data }) => {
      dispatch(Actions.setDialogs(data));
    })
    .catch(() => {
      dispatch(Actions.setIsLoading(false));
    });
  },

  removeDialogs: (id) => (dispatch) => {
    dialogsApi.getAll().then(({ data }) => {
      dispatch(Actions.setDialogs(data));
      dispatch({
        type: 'DIALOGS:REMOVE_DIALOG',
        deletedId: id
      })
    })
    .catch(() => {
      dispatch(Actions.setIsLoading(false));
    });
  },

  removeDialogById: (id) => (dispatch) => {
      dialogsApi
        .removeById(id)
        .then(({ data }) => {
          dispatch({
            type: 'DIALOGS:REMOVE_DIALOG',
            payload: id
          });
        })
        .catch(() => {
          dispatch(Actions.setIsLoading(false));
        });
        
  },
};

export default Actions;
