import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import helpers from '../common/helpers';
import React from 'react';
import Button from '../views/Chat/ChatView/components/Button';
const { getUserInfo } = helpers;

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: {
      "Unlike": "Unlike",
      "Like": "Like",
      "Super Like": "Super Like",
      "Next": "Next",
      "Back": "Back",
      "cancel": "Cancel",
      "Account": "Account",
      "Settings": "Settings",
      "Log out": "Log out",
      "Profile complete": "Profile complete",
      "Main": "Main",
      "Statistic": "Statistic",
      "Items": "Items",
      "Chat": "Chat",
      "Pairs": "Pairs",
      "Closed Pairs": "Closed Pairs",
      "DISLIKES": "DISLIKES",
      "LIKES": "LIKES",
      "SUPERLIKES": "SUPERLIKES",
      "TOTAL CHANGING": "TOTAL CHANGING",
      "Latest Changings": "Latest Changings",
      "closed": "closed",
      "Latest Likes": "Latest Likes",
      "Upload images": "Upload images",
      "submit": "submit",
      "Upload file": "Upload file",
      "Image and title": "Image and title",
      "Description": "Description",
      "Edit or Delete": "Edit or Delete",
      "Add item": "Add item",
      "Please upload image": "Please upload image",
      "Product created": "Product created",
      "Title": "Title",
      "Create new item": "Create new item",
      "Pending": "Pending",
      "Draft": "Draft",
      "Active": "Active",
      "Status": "Status",
      "Category": "Category",
      "auto and transport":"Auto and transport",
      "appliances":"Appliances",
      "computer equipment":"Computer equipment",
      "phones and tablets":"Phones and tablets",
      "electronics":"Electronics",
      "women's Wardrobe":"Women's Wardrobe",
      "men's Wardrobe":"Men's Wardrobe",
      "for children and mothers":"For children and mothers",
      "furniture":"Furniture",
      "repair and construction":"Repair and construction",
      "garden":"garden",
      "hobbies, sports and tourism":"Hobbies, sports and tourism",
      "various":"Various",
      "Create":"Create",
      "Cancel":"Cancel",
      "Edit item":"Edit item",
      "one image":"Product must has at least one image",
      "Update":"Update",
      "Delete image":"Delete image",
      "Delete message":"Delete message",
      "online":"online",
      "offline":"offline",
      "Delete dialog":"Delete dialog",
      "Delete":"Delete",
      "Close the deal":"Close the deal",
      "Jump to chat":"Jump to chat",
      "The information can be edited":"The information can be edited",
      "Profile":"Profile",
      "First name":"First name",
      "Last name":"Last name",
      "Email Address":"Email Address",
      "Phone Number":"Phone Number",
      "Country":"Country",
      "City":"City",
      "Categories":"Categories",
      "Upload avatar":"Upload avatar",
      "Save details":"Save details",
      "Save":"Save",
      "English":"English",
      "Russian":"Russian",
      "Language":"Language",
      "Set Language":"Set Language",
      "Notifications":"Notifications",
      "Manage the notifications":"Manage the notifications",
      "Notifications and Messages":"Notifications and Messages",
      "Chat Messages":"Chat Messages",
      "Pair Notifications":"Pair Notifications",
      "System Notifications":"System Notifications",
      "Update password":"Update password",
      "Password":"Password",
      "Confirm password":"Confirm password",
      "Loading":"Loading...",
      "Dialog is empty":"Dialog is empty",
      "Open Dialog":"Open Dialog",
      "Registration":"Registration",
      "Create new account":"Create new account",
      "Login":"Login",
      "Swap anything":"Swap anything you don't need for anything you want",
      "Traditional items":"Traditional items exchange brought",
      "to online world":"to online world",
      "Discover Our Categories":"Discover Our Categories",
      "How it works?":"How it works?",
      "Add your items for swap":"Add your items for swap",
      "Like and Super like items of other users":"Like and Super like items of other users",
      "Swap items if pairs matched":"Swap items if pairs matched",
      "Get started":"Get started",
      "Our Categories":"Our Categories",
      "Advantages":"Advantages",
      "Unlimited":"Unlimited",
      "You can add any quantity of items":"You can add any quantity of items",
      "Free":"Free",
      "You pay nothing to swap with other people":"You pay nothing to swap with other people",
      "New experiences":"New experiences",
      "Swap only if the pair is matched":"Swap only if the pair is matched",
      "We will send you our best offers, once a week.":"We will send you our best offers, once a week.",
      "Keep me updated":"Keep me updated",
      "Notifications about new products and promotions":"Notifications about new products and promotions",
      "Receive offers":"Receive offers",
      "Your email":"Your email",
      "Got any questions? Need help?":"Got any questions? Need help?",
      "We are here to help. Get in touch!":"We are here to help. Get in touch!",
      "Sign in":"Sign in",
      "Sign up":"Sign up",
      "Don\'t have an account?":"Don\'t have an account?",
      "Now you can Log in":"Now you can Log in",
      "Have an account?":"Have an account?",
      "Product must has at list one image":"Product must has at list one image",
      "Nothing to show":"Nothing to show",
      "We sent our master to fix it":"We sent our master to fix it",
      "Something went wrong":"Something went wrong",
      "Try again":"Try again",
      "Please add at least one product. You can\'t swap without own product":"Please add at least one product. You can\'t swap without own product",
      "Product was removed":"Product was removed",
    }
  },
  ru: {
    translation: {
      "Unlike": "Не нравится",
      "Like": "Нравится",
      "Super Like": "Очень нравится",
      "Next": "Далее",
      "Back": "Назад",
      "cancel": "Отмена",
      "Account": "Аккаунт",
      "Settings": "Настройки",
      "Log out": "Выход",
      "Profile complete": "Профиль заполнен",
      "Main": "Главная",
      "Statistic": "Статистика",
      "Items": "Товары",
      "Chat": "Чат",
      "Pairs": "Пары",
      "Closed Pairs": "Завершенные обмены",
      "DISLIKES": "ДИЗЛАЙКИ",
      "LIKES": "ЛАЙКИ",
      "SUPERLIKES": "СУПЕР ЛАЙКИ",
      "TOTAL CHANGING": "ВСЕГО ОБМЕНОВ",
      "Latest Changings": "Последние обмены",
      "Image": "Изображение",
      "Name": "Имя",
      "Date": "Дата",
      "Status": "Статус",
      "closed": "закрыт",
      "Latest Likes": "Последние лайки",
      "Upload images": "Загрузить изображения",
      "submit": "Загрузить",
      "Upload file": "Загрузить файл",
      "Image and title": "Изображение и заголовок",
      "Description": "Описание",
      "Edit or Delete": "Изменить или Удалить",
      "pending": "В ожидании ",
      "draft": "Черновик",
      "active": "Активный",
      "Add item": "Добавить товар",
      "Please upload image": "Загрузите изображение, пожалуйста",
      "Product created": "Продукт создан",
      "Title": "Заголовок",
      "Create new item": "Добавление товара",
      "Pending": "В ожидании",
      "Draft": "Черновик",
      "Active": "Активный",
      "Category": "Категория",
      "auto and transport":"Авто и транспорт",
      "appliances":"Техника",
      "computer equipment":"Компьютеры и комплектующие",
      "phones and tablets":"Телефоны и планшеты",
      "electronics":"Электроника",
      "women's Wardrobe":"Женский гардероб",
      "men's Wardrobe":"Мужской гардероб",
      "for children and mothers":"Для детей и мам",
      "furniture":"Мебель",
      "repair and construction":"Ремонт и строительство",
      "garden":"Сад",
      "hobbies, sports and tourism":"Хобби, спорт и туризм",
      "various":"Разное",
      "Create":"Добавить",
      "Cancel":"Отмена",
      "Edit item":"Редактировать товар",
      "one image":"У продукта должно быть минимум одно изображение",
      "Update":"Обновить",
      "Delete image":"Удалить изображение",
      "Delete message":"Удалить сообщение",
      "online":"онлайн",
      "offline":"офлайн",
      "Delete dialog":"Удалить диалог",
      "Delete":"Удалить",
      "Close the deal":"Закрыть сделку",
      "Jump to chat":"Перейти в чат",
      "The information can be edited":"Информация может быть изменена",
      "Profile":"Профиль",
      "First name":"Имя",
      "Last name":"Фамилия",
      "Email Address":"Email",
      "Phone Number":"Номер телефона",
      "Country":"Страна",
      "City":"Город",
      "Categories":"Категории",
      "Upload avatar":"Загрузить аватар",
      "Save details":"Сохранить данные",
      "Save":"Сохранить",
      "English":"Английский",
      "Russian":"Русский",
      "Language":"Язык",
      "Set Language":"Установить язык",
      "Notifications":"Уведомления",
      "Manage the notifications":"Настройка уведомлений",
      "Notifications and Messages":"Уведомления и сообщения",
      "Chat Messages":"Сообщения из чата",
      "Pair Notifications":"Уведомления о парах",
      "System Notifications":"Системные уведомления",
      "Update password":"Обновить пароль",
      "Password":"Пароль",
      "Confirm password":"Подтверждение пароля",
      "Loading":"Загрузка сообщений...",
      "Dialog is empty":"Диалог пуст",
      "Open Dialog":"Откройте диалог",
      "Registration":"Регистрация",
      "Create new account":"Создать аккаунт",
      "Login":"Вход",
      "Swap anything":"Обменяйте все, что вам не нужно, на все, что вы хотите",
      "Traditional items":"Традиционный обмен предметами принесен",
      "to online world":"в онлайн-мир",
      "Discover Our Categories":"Посмотреть категории",
      "How it works?":"Как это работает?",
      "Add your items for swap":"Добавьте свои товары для обмена",
      "Like and Super like items of other users":"Оценивайте товары других",
      "Swap items if pairs matched":"Обменивайтесь если пары совпали",
      "Get started":"Начать",
      "Our Categories":"Наши категории",
      "Advantages":"Преимущества",
      "Unlimited":"Без ограничений",
      "You can add any quantity of items":"Добавляйте неограниченное количество товаров",
      "Free":"Бесплатно",
      "You pay nothing to swap with other people":"Вы не платите ничего, чтобы обмениваться",
      "New experiences":"Новый опыт",
      "Swap only if the pair is matched":"Обменивайтесь, только если интерес взаимный",
      "We will send you our best offers, once a week.":"Мы будем присылать вам лучшие предложения раз в неделю",
      "Keep me updated":"Держать меня в курсе",
      "Notifications about new products and promotions":"Уведомления о появвлении новых товаров и акциях",
      "Receive offers":"Получать предложения",
      "Your email":"Email",
      "Got any questions? Need help?":"Есть вопросы? Нужна помощь?",
      "We are here to help. Get in touch!":"Мы здесь, чтобы помочь. Свяжитесь с нами!",
      "Sign in":"Вход",
      "Sign up":"Регистрация",
      "Don\'t have an account?":"Нет аккаунта?",
      "Now you can Log in":"Сейчас вы можете войти",
      "Have an account?":"Уже есть аккаунт?",
      "Product must has at list one image":"Продукт должен иметь минимум одно изображение",
      "Nothing to show":"Больше товаров нет",
      "We sent our master to fix it":"Мы отправили нашего мастера исправить это",
      "Something went wrong":"Что-то пошло не так",
      "Try again":"Попытаться еще",
      "Please add at least one product. You can\'t swap without own product":"Пожалуйста, добавьте хотя бы один продукт. Вы не можете обменяться без своего собственного продукта",
      "Product was removed":"Продукт был удален",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getUserInfo()
      ? getUserInfo().lang
      : /^ru\b/.test(navigator.language)
      ? 'ru'
      : 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
