import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { createMuiTheme, withStyles, ThemeProvider } from '@material-ui/core/styles';
import { purple, pink } from '@material-ui/core/colors';
import Page from 'src/components/Page';
import Button from '@material-ui/core/Button';
import StarsIcon from '@material-ui/icons/Stars';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import Fab from '@material-ui/core/Fab';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Tooltip from '@material-ui/core/Tooltip';
import Tinder from './Tinder';
import apiService from "../../../services/apiService";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import PairPopUp from "./PairPopUp";
import dialogsApi from '../../Chat/ChatView/utils/api/dialogs';
import CardSkeleton from "../../../components/CardSkeleton";
import socket from '../../Chat/ChatView/core/socket';
import { useTranslation } from 'react-i18next';
import Notice from "../../../components/Notice";
import helpers from '../../../common/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  space: {
    marginBottom: 10,
  },
  button: {
    margin: theme.spacing(1),
  },
  buttonLike: {
    minWidth: 106,
  },
  buttons: {
    marginBottom: theme.spacing(3),
  },
  margin: {
    margin: theme.spacing(1),
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },
}))(Fab);

const theme = createMuiTheme({
  palette: {
    secondary: pink,
    success: purple,
  },
});


const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Main = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [characters, setCharacters] = useState([]);
  const [notice, setNotice] = useState({message: '', severity: 'error'});
  const [openNotice, setOpenNotice] = useState(false);
  const [buttonsDisable, setButtonsDisable] = useState(false);
  const [openPairPopUp, setOpenPairPopUp] = useState(false);
  const [pair, setPair] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogId, setDialogId] = useState('');

  const {getMainProducts, likeProduct, dislikeProduct, superLikeProduct} = apiService;
  const { getUserInfo } = helpers;
  const { products } = getUserInfo();
  const { t } = useTranslation();

  useEffect( () => {
    let cleanup = false;
    setLoading(true);
    const fetchData = async () => {
      const response = await getMainProducts();
      if(!cleanup){
        setCharacters(response.data)
        setLoading(false)
      }
    };
    fetchData();
    return () => cleanup = true;
  },[])

  useEffect( () => {
    const fetchData = async () => {
      const response = await getMainProducts();
      setCharacters((prevCharacters) => [...response.data.slice(2, response.data.length), ...prevCharacters]);
    };
    if(characters.length === 2){
      fetchData()
    }
  },[characters])

  const hideCard = (elem, type) => {
    const moveOutWidth = document.body.clientWidth * 1.5;
    const getAnimation = (direction) => {
      switch (direction) {
        case 'left':
          return `rotate(-30deg) translateX(-${moveOutWidth + 300}px)`;
        case 'right':
          return `rotate(30deg) translateX(${moveOutWidth + 300}px)`;
        case 'up':
          return `translateY(-${moveOutWidth + 300}px)`;
        default:
          return null;
      }
    };

    if (elem) {
      elem.animate([
        { transform: getAnimation(type) },
      ], {
        duration: 1000,
        iterations: 1
      }).onfinish = function () {
        setCharacters((prevChar) => prevChar.splice(0, prevChar.length - 1) || []);
        setButtonsDisable(false)
      };
    }
  };

  const onAddDialogChat = (pair) => {
    dialogsApi
      .create({
        author: pair.userOne.id,
        partner: pair.userTwo.id,
        productOne: pair.productOne,
        productTwo: pair.productTwo,
      })
        .catch((err) => {
        console.log('mistake - createDialog', err)
      });
    socket.on('SERVER:DIALOG_Id', ({createdDialogId}) => {
      setDialogId(createdDialogId);
    });
  };

  const handleLike = async (direction, type, id, user) => {
    setButtonsDisable(true);
    const cards = document.querySelectorAll('.swipe');
    if (type !== 'swipe') {
      hideCard(cards[cards.length - 1], direction);
      setNotice({message: 'Item was Liked', severity: 'success', icon: <FavoriteIcon />});
      setOpenNotice(true);
      const result = await likeProduct(characters[characters.length - 1]._id, characters[characters.length - 1].user);
      if (result.status === 200 && result.data !== 'like' && result.data[0].productOne !== null && result.data[0].productTwo !== null) {
        onAddDialogChat(result.data[0])
        setPair({pair: result.data[0], type: 'like'});
        setOpenPairPopUp(true);
      }
    } else {
      setNotice({message: 'Item was Liked', severity: 'success', icon: <FavoriteIcon />});
      setOpenNotice(true);
      const result = await likeProduct(id, user);
      if (result.status === 200 && result.data !== 'like' && result.data[0].productOne !== null && result.data[0].productTwo !== null) {
        onAddDialogChat(result.data[0])
        setPair({pair: result.data[0], type: 'like'});
        setOpenPairPopUp(true);
      }
    }
  };

  const handleSuperLike = async (direction, type, id, user) => {
    setButtonsDisable(true);
    const cards = document.querySelectorAll('.swipe');
    if (type !== 'swipe'){
      hideCard(cards[cards.length - 1], direction);
      setNotice({message: 'Item was Super Like', severity: 'info', icon: <StarsIcon />});
      setOpenNotice(true);

      const result = await superLikeProduct(characters[characters.length - 1]._id, characters[characters.length - 1].user);
      if (result.status === 200 && result.data !== 'superLike' && result.data[0].productOne !== null && result.data[0].productTwo !== null) {
        setPair({pair: result.data[0], type: 'superLike'});
        setOpenPairPopUp(true);
        onAddDialogChat(result.data[0])
      }
    } else {
      setNotice({message: 'Item was Super Like', severity: 'info', icon: <StarsIcon />});
      setOpenNotice(true);

      const result = await superLikeProduct(id, user);
      if (result.status === 200 && result.data !== 'superLike' && result.data[0].productOne !== null && result.data[0].productTwo !== null) {
        setPair({pair: result.data[0], type: 'superLike'});
        setOpenPairPopUp(true);
        onAddDialogChat(result.data[0])
      }
    }
  };

  const handleDisLike = async (direction, type, id, user) => {
    setButtonsDisable(true);
    const cards = document.querySelectorAll('.swipe');
    if (type !== 'swipe'){
      hideCard(cards[cards.length - 1], direction);
      setNotice({message: 'Item was Disliked', severity: 'error', icon: <ThumbDownIcon />});
      setOpenNotice(true);
      dislikeProduct(characters[characters.length - 1]._id, characters[characters.length - 1].user);
    } else {
      setNotice({message: 'Item was Disliked', severity: 'error', icon: <ThumbDownIcon />});
      setOpenNotice(true);
      dislikeProduct(id, user);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotice(false);
  };

  const handleClosePairPopUp = () => {
    setOpenPairPopUp(false);
  };

  const handleJumpToChat = () => {
    setOpenPairPopUp(false);
    return navigate(`/app/chat/dialog/${dialogId}`, { state: '/' });
  };

  return (
     <Page className={classes.root} title="Main">
      <Container maxWidth={false}>
        <Snackbar open={openNotice} autoHideDuration={500} onClose={handleClose}>
          <Alert onClose={handleClose} icon={notice.icon} severity={notice.severity}>
            {notice.message}
          </Alert>
        </Snackbar>
        {pair
          ?<PairPopUp openPairPopUp={openPairPopUp} pairProduct={pair} handleClosePairPopUp={handleClosePairPopUp} handleJumpToChat={handleJumpToChat}/>
          : null
        }

        {products.length === 0 ? <Notice text={t("Please add at least one product. You can't swap without own product")}/> : null}

        {loading
          ? <Grid className="tinder" container spacing={0}>
            <Grid container spacing={0} direction="column" alignItems="center" justify="center" item lg={12} md={12} xl={12} xs={12}>
              <CardSkeleton/>
            </Grid>
          </Grid>
          : <>
            <Grid className="tinder" container spacing={0}>
              <Grid container spacing={0} direction="column" alignItems="center" justify="center" item lg={12} md={12} xl={12} xs={12}>
                <Tinder
                  characters={characters}
                  setCharacters={setCharacters}
                  hanlLike={handleLike}
                  handleSuperLike={handleSuperLike}
                  handleDisLike={handleDisLike}
                  setButtonsDisable={setButtonsDisable}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0} direction="row" alignItems="center" justify="center" item lg={12} md={12} xl={12} xs={12}>
              {characters.length !== 0
                ? <div className={classes.buttons}>
                  <ThemeProvider theme={theme}>
                    <Tooltip title={t('Unlike')} aria-label="unlike">
                      <Button
                        variant="contained"
                        disabled={buttonsDisable}
                        color="secondary"
                        startIcon={<ThumbDownIcon />}
                        onClick={() => handleDisLike('left')}
                      >
                        {t('Unlike')}
                      </Button>
                    </Tooltip>
                  </ThemeProvider>
                  <Tooltip title= {t('Super Like')} aria-label="super-like">
                    <ColorButton disabled={buttonsDisable} color="primary" className={classes.button} onClick={() => handleSuperLike('up')}>
                      <StarsIcon />
                    </ColorButton>
                  </Tooltip>
                  <Tooltip title={t('Like')} aria-label="like">
                    <Button
                      variant="contained"
                      disabled={buttonsDisable}
                      color="secondary"
                      className={classes.buttonLike}
                      startIcon={<FavoriteIcon />}
                      onClick={() => handleLike('right')}
                    >
                      {t('Like')}
                    </Button>
                  </Tooltip>
                </div>
                : null }
            </Grid>
          </>
        }
        </Container>
    </Page>
  );
};

export default Main;
