import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AddProductForm from "./AddProductForm";
import { useTranslation } from 'react-i18next';


const AddProductPopUp = ({open, handleClickOpen, handleClosePopUp, setItems,  loading, setLoading }) => {
  const { t } = useTranslation();

  return (
    <>
      <Button  color="primary" variant="contained" onClick={handleClickOpen}>
        {t('Add item')}
      </Button>
      <Dialog open={open} keepMounted onClose={handleClosePopUp}>
        <DialogContent>
          <AddProductForm setItems={setItems}  loading={loading} setLoading={setLoading}  handleClosePopUp={handleClosePopUp}/>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default AddProductPopUp;
