import React, {useContext, useState} from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles, FormHelperText
} from '@material-ui/core';
import Page from 'src/components/Page';
import apiService from "../../services/apiService";
import helpers from '../../common/helpers';
import UserInfoContext from "../../common/userInfoContext";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = (props) => {
  const classes = useStyles();
  const [serverError, setServerError] = useState(null);
  const [userInfo, setUserInfoContext] = useContext(UserInfoContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { handleCloseForm } = props;
  const { setToken, setUserInfoFromToken, getUserInfo } = helpers;
  const { t, i18n } = useTranslation();

  const onHandleSubmit = async (values, setSubmitting) => {
    const { login } = apiService;
    const result = await login(values);
    if (result.status === 200) {
      setToken(result.data.token);
      setUserInfoFromToken(result.data.token);
      const userData = getUserInfo()
      setUserInfoContext(userData);
      i18n.changeLanguage(userData.lang);
      if (location.pathname !== '/login'){
        if(typeof handleCloseForm === 'function' ){
          handleCloseForm();
        }
        setSubmitting(false)
        return navigate('/app/account', { state: '/' });
      }
      setSubmitting(false)
      return navigate('/app/account', { state: '/' });
    } else {
      setServerError('Something went wrong');
      setSubmitting(false)
      setTimeout(() => {setServerError(null)}, 2000)
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().max(255).required('Email is required'),
              password: Yup.string().max(255).required('Password is required')
            })}
            onSubmit={async (values, { setSubmitting }) => {
              await onHandleSubmit(values, setSubmitting);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    {t('Sign in')}
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label={t("Email")}
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label={t("Password")}
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                    {t("Sign in")}
                  </Button>
                </Box>
                {Boolean(serverError) && (
                  <FormHelperText error>
                    {serverError}
                  </FormHelperText>
                )}
                <Typography color="textSecondary" variant="body1">
                  {t("Don't have an account?")}
                  {' '}
                  <Link component={RouterLink} to="/registration" onClick={handleCloseForm}  variant="h6">
                    {t('Sign up')}
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
