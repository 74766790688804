import React, {useState} from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  MenuItem,
  Container,
  InputLabel,
  Select,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import apiService from "../../../services/apiService";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {PRODUCT_CATEGORIES} from '../../../common/config';
import UploadProductGallery from "./UploadProductGallery";
import EditGallery from "./EditGallery";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonCancel:{
    margin: theme.spacing(1)
  }
}));


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const EditProductForm = ({ handleClosePopUp, onUpdateHandle, gallery, setGallery, item }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [notice, setNotice] = useState({message: '', severity: 'error'});
  const [imageGallery, setImageGallery] = useState([]);
  const [initialValues] = useState({
    title: item.title,
    description: item.description,
    category: item.category,
    status: item.status
  });
  const { t } = useTranslation();

  const onHandleSubmit = async (values) => {
    const { updateUserItem } = apiService;
    const result = await updateUserItem(values, imageGallery, item._id);
    if (result.status === 200) {
      setNotice({message: 'Product was updated', severity: 'success'});
      setOpen(true);
      setTimeout(() => {
        handleClosePopUp();
      }, 900)
      onUpdateHandle(result.data)
    } else {
      setNotice({message: 'Somethings went wrong', severity: 'error'});
      setOpen(true);
    }

  };

  const handleOnSaveGallery = (values) => {
    setImageGallery(values)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
      <Container maxWidth="sm">
        <Formik
          initialValues={initialValues}
          validationSchema={
            Yup.object().shape({
              title: Yup.string().min(4).max(105).required('title is required'),
              description: Yup.string().min(4).max(105).required('description is required'),
              category: Yup.string().required('category is required'),
              status: Yup.string().required('status is required'),
            })
          }
          onSubmit={async (values) => {
            await onHandleSubmit(values)
          }}
        >
          {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Typography color="textPrimary" variant="h2">
                  {t("Edit item")}
                </Typography>
              </Box>
              {gallery.length !== 0
                ? <EditGallery gallery={gallery} setGallery={setGallery} id={item._id}/>
                : <Typography color="textPrimary" variant="h2">{t("one image")}</Typography> }
              <TextField
                error={Boolean(touched.title && errors.title)}
                fullWidth
                helperText={touched.title && errors.title}
                label={t("Title")}
                margin="normal"
                name="title"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.title}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.description && errors.description)}
                fullWidth
                helperText={touched.description && errors.description}
                label={t("Description")}
                margin="normal"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
              <FormControl className={classes.formControl}>
              <InputLabel id="status-label">{t("Status")}</InputLabel>
                <Select
                  label="Status"
                  labelId="status-label"
                  id="status-select"
                  value={values.status}
                  onChange={handleChange}
                  error={Boolean(touched.status && errors.status)}
                  fullWidth
                  onBlur={handleBlur}
                  name="status"
                >
                  <MenuItem value='pending'>{t("Pending")}</MenuItem>
                  <MenuItem value='draft'>{t("Draft")}</MenuItem>
                  <MenuItem value='active'>{t("Active")}</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
              <InputLabel id="category-label">{t("Category")}</InputLabel>
                <Select
                  label="Category"
                  labelId="category-label"
                  id="category-select"
                  value={values.category}
                  onChange={handleChange}
                  error={Boolean(touched.category && errors.category)}
                  fullWidth
                  onBlur={handleBlur}
                  name="category"
                >
                  {PRODUCT_CATEGORIES.map((item) => <MenuItem key={item} value={item}>{t(item)}</MenuItem>) }
                </Select>
              </FormControl>
              <UploadProductGallery  className={classes.buttonCancel} handleOnSaveGallery={handleOnSaveGallery} />
              <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={notice.severity}>
                  {notice.message}
                </Alert>
              </Snackbar>
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleClosePopUp} className={classes.buttonCancel} color="primary" disabled={isSubmitting} fullWidth size="large"  variant="outlined" >
                  {t('Cancel')}
                </Button>
                <Button className={classes.buttonCancel} color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
                  {t('Update')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default EditProductForm;
