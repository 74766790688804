import React, {useEffect, useState} from 'react';
import {
  Box, Button,
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import ProductCard from './ProductCard';
import apiService from "../../../services/apiService";
import helpers from "../../../common/helpers";
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "../../../components/ErrorFallback";
import DeleteIcon from "@material-ui/icons/Delete";
import ResultNotice from "../../../components/ResultNotice";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ClosedPairsList = () => {
  const classes = useStyles();
  const [pairs, setPairs] = useState([]);
  const [openNotice, setOpenNotice] = useState(false);
  const [noticeInfo, setNoticeInfo] = useState({messageNotice: 'Success', severityNotice: 'success'});
  const {
    getProductOfCurrentUser,
    getProductGalleryOfCurrentUser,
    getProductOfPartnerUser,
    getProductGalleryOfPartnerUser,
    getPartnerUser,
    getCurrentUser} = helpers;
  const {getUserClosedPairs, deleteUserClosedPair} = apiService;
  const { t } = useTranslation();


  useEffect( () => {
    let cleanup = false;
    const fetchData = async () => {
      const response = await getUserClosedPairs();
      if(!cleanup){
        setPairs(response.data)
      }
    };
    fetchData();
    return () => cleanup = true;
  },[]);

  const handleCloseNotice = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotice(false);
  };

  const handleRemovePair = async (event) => {
    const result = await deleteUserClosedPair(event.currentTarget.getAttribute('pair'))
    if(result.status !== 200){
      setNoticeInfo({messageNotice: 'Somethings went wrong', severityNotice: 'error'})
      setOpenNotice(true);
      console.log('result', result)
    }else {
      setNoticeInfo({messageNotice: 'Item was delete successfully', severityNotice: 'success'})
      setOpenNotice(true);
      console.log('result.data', result.data)
      setPairs(result.data)
    }
  };

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Box mt={3}>
            {pairs.map((product) => (
              <div  key={product._id}>
                <Grid container spacing={3} key={product._id} justify="center" alignItems="center">
                  <Grid item >
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <ProductCard
                      user={getCurrentUser(product)}
                      className={classes.productCard}
                      product={getProductOfCurrentUser(product)}
                      imageGallery={getProductGalleryOfCurrentUser(product)}/>
                    </ErrorBoundary>
                  </Grid>
                  <Grid item >
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <ProductCard
                      user={getPartnerUser(product)}
                      className={classes.productCard}
                      product={getProductOfPartnerUser(product)}
                      imageGallery={getProductGalleryOfPartnerUser(product)} />
                    </ErrorBoundary>
                  </Grid>
                </Grid>
                <Box mt={3} mb={3} display="flex" justifyContent="center">
                  <Button
                  variant="contained"
                  key={product._id}
                  color="default"
                  pair={product._id}
                  onClick={handleRemovePair}
                  className={classes.button}
                  startIcon={<DeleteIcon />} >
                    {t('Delete')}
                  </Button>
                </Box>
              </div>
            ))}
        </Box>
        <ResultNotice
          openNotice={openNotice}
          handleCloseNotice={handleCloseNotice}
          severityNotice={noticeInfo.severityNotice}
          messageNotice={noticeInfo.messageNotice}
        />
        <Box mt={3} display="flex" justifyContent="center">
          <Pagination color="primary" count={3} size="small"/>
        </Box>
      </Container>
    </Page>
  );
};

export default ClosedPairsList;
