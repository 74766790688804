/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import StatusBase from '../components/Status/index';

const Status = ({ currentDialogId, user, dialogs }) => {
  if (dialogs === undefined || !dialogs.length || !currentDialogId) {
    return null;
  }
  const currentDialogObj = dialogs.filter(
    (dialog) => dialog._id === currentDialogId
  )[0];

  let partner = {};
  let productOne = {};
  let productTwo = {};
  let removedId = {};
  if (currentDialogObj) {
  if (currentDialogObj.author._id === user._id) {
    partner = currentDialogObj.partner;
    productOne = currentDialogObj.productOne;
    productTwo = currentDialogObj.productTwo;
    removedId = currentDialogObj.removedId;
  } else {
    partner = currentDialogObj.author;
    productOne = currentDialogObj.productTwo;
    productTwo = currentDialogObj.productOne;
    removedId = currentDialogObj.removedId;
  }
}
  return <StatusBase online={partner.isOnline} login={partner.firstName} productOne={productOne} productTwo={productTwo} removedId={removedId}/>;
};

export default connect(({ dialogs, user }) => ({
  dialogs: dialogs.items,
  currentDialogId: dialogs.currentDialogId,
  user: user.data,
}))(Status);
