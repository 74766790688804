import axios from 'axios';
import { API_BASE_URL } from '../../../../../common/config';

export default {
  getAllByDialogId: (id) => axios.get(`${API_BASE_URL}/messages?dialog=${id}`),
  removeById: (id) => axios.delete(`${API_BASE_URL}/messages?id=${id}`),
  send: (text, dialogId, attachments) =>  axios.post(`${API_BASE_URL}/messages`, {
    text,
    dialog_id: dialogId,
    attachments
  })
};
