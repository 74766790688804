import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import LoginView from '../../views/auth/LoginView';
import RegisterView from '../../views/auth/RegisterView';
import DraggableDialog from '../PopUp';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [openRegistration, setOpenRegistration] = useState(false);
  const [openLogin, setOpenLogin] = React.useState(false);
  const { t } = useTranslation();


  const handleCloseForm = () => {
    setOpenRegistration(false);
    setOpenLogin(false);
  };

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
  };

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  const handleClickOpenRegistration = () => {
    setOpenRegistration(true);
  };

  const handleCloseRegistration = () => {
    setOpenRegistration(false);
  };


  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <DraggableDialog
          color="default"
          text={t("Registration")}
          title={t("Create new account")}
          open={openRegistration}
          handleClose={handleCloseRegistration}
          handleClickOpen={handleClickOpenRegistration}
        >
          <RegisterView handleCloseForm={handleCloseForm} />
        </DraggableDialog>
        <DraggableDialog
          className={classes.button}
          color="default"
          text={t("Login")}
          title={t("Login")}
          open={openLogin}
          handleClose={handleCloseLogin}
          handleClickOpen={handleClickOpenLogin}
        >
          <LoginView handleCloseForm={handleCloseForm} />
        </DraggableDialog>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
