import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useState } from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import helpers from './common/helpers';
import UserInfoContext from './common/userInfoContext';

const App = () => {

  const { getUserInfo } = helpers;
  const user = getUserInfo();
  const routing = useRoutes(routes(user));
  const userInfo = useState(user);
  return (
    <ThemeProvider theme={theme}>
      <UserInfoContext.Provider value={userInfo}>
          <GlobalStyles />
          {routing}
      </UserInfoContext.Provider>
    </ThemeProvider>
  );
};

export default App;
