import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import Main from './views/main/MainView';
import Chat from './views/Chat/ChatView';
import ClosedPairsList from "./views/closed-pairs/ClosetPairsView";
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "./components/ErrorFallback";
import LandingPage from "./views/landing-page";

const routes = (user) => [
  {
    path: 'app',
    element: user ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <ErrorBoundary FallbackComponent={ErrorFallback}><AccountView /></ErrorBoundary> },
      { path: 'items', element: <ErrorBoundary FallbackComponent={ErrorFallback}><CustomerListView /></ErrorBoundary> },
      { path: 'statistics', element: <ErrorBoundary FallbackComponent={ErrorFallback}><DashboardView /></ErrorBoundary> },
      { path: 'main', element: <ErrorBoundary FallbackComponent={ErrorFallback}><Main /></ErrorBoundary> },
      { path: 'pairs', element: <ErrorBoundary FallbackComponent={ErrorFallback}><ProductListView /></ErrorBoundary> },
      { path: 'closed-pairs', element: <ErrorBoundary FallbackComponent={ErrorFallback}><ClosedPairsList /></ErrorBoundary> },
      { path: 'settings', element: <ErrorBoundary FallbackComponent={ErrorFallback}><SettingsView /></ErrorBoundary> },
      { path: 'chat/*', element: <ErrorBoundary FallbackComponent={ErrorFallback}><Chat /></ErrorBoundary> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: !user ? <MainLayout /> : <Navigate to="/app/account" />,
    children: [
      { path: '/', element: <ErrorBoundary FallbackComponent={ErrorFallback}><LandingPage /></ErrorBoundary> },
      { path: 'login', element: <ErrorBoundary FallbackComponent={ErrorFallback}><LoginView /></ErrorBoundary> },
      { path: 'registration', element: <ErrorBoundary FallbackComponent={ErrorFallback}><RegisterView /></ErrorBoundary> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
