import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import ProgressLine from '../../../components/ProgressLine';
import helpers from '../../../common/helpers';
import UploadAvatar from '../../../components/UploadAvatar';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ className, profileInfo, handleOnSaveAvatar, ...rest }) => {
  const classes = useStyles();
  const { getProfileCompleat } = helpers;
  const { t } = useTranslation();


  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Box alignItems="center" display="flex" flexDirection="column">
          {profileInfo.avatar
            ? <Avatar className={classes.avatar} src={profileInfo.avatar}/>
            : <Avatar className={classes.avatar} />
          }
          <Typography color="textPrimary" gutterBottom variant="h3">
            {profileInfo.firstName || profileInfo.login}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {`${profileInfo.city} ${profileInfo.country}`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {t('Profile complete')}
          </Typography>
          <ProgressLine compleat={getProfileCompleat(profileInfo)} />
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <UploadAvatar handleOnSaveAvatar={handleOnSaveAvatar} />
      </CardActions>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
