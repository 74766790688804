/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import generateAvatarFromHash from '../../utils/helpers/generateAvatarFromHash';

import './Avatar.scss';

const Avatar = ({ user }) => {
  const [previewImage, setPreviewImage] = useState(null);

  if (user.avatar) {
    return (
      <>
        <img
          className="avatar"
          src={user.avatar}
          alt={`Avatar ${user.login}`}
          onClick={() => setPreviewImage(user.avatar)}
        ></img>

        <Modal
          visible={!!previewImage}
          onCancel={() => setPreviewImage(null)}
          footer={null}
        >
          <img src={previewImage} style={{ width: '100%' }} alt="Preview" />
        </Modal>
      </>
    );
  }
  const { color, colorLighten } = generateAvatarFromHash(user._id);
  const firstChar = user.firstName[0].toUpperCase();
  return (
    <div
      style={{
        background: `linear-gradient(135deg, ${color} 0%, ${colorLighten} 96.52%)`
      }}
      className="avatar avatar--symbol"
    >
      {firstChar}
    </div>
  );
};

Avatar.propTypes = {
  className: PropTypes.string
};

export default Avatar;
