import React, {useContext, useState} from 'react';
import apiService from "../../../services/apiService";
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import helpers from '../../../common/helpers';
import UserInfoContext from "../../../common/userInfoContext";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(({
  root: {}
}));

const Language = ({ className, ...rest }) => {
  const classes = useStyles();
  const { updateUserLanguage } = apiService;
  const { getUserInfo, setUserInfo } = helpers;

  const [lang, setLang] = useState(getUserInfo().lang);
  const [open, setOpen] = useState(false);
  const [notice, setNotice] = useState({message: '', severity: 'error'});
  const [userInfo, setUserInfoContext] = useContext(UserInfoContext);
  const { t, i18n } = useTranslation();

  const handleChange = (event) => {
    setLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const onSaveLanguage = async () => {
    const result = await updateUserLanguage(lang);
    if(result.status === 200){
      setUserInfoContext(result.data);
      setUserInfo(result.data);
      setNotice({message: 'Language was saved', severity: 'success'});
      setOpen(true);
    }else {
      setNotice({message: 'Somethings went wrong', severity: 'error'});
      setOpen(true);
    }

  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <form className={clsx(classes.root, className)}{...rest}>
      <Card>
        <CardHeader subheader={t("Set Language")} title={t("Language")}/>
        <Divider />
        <CardContent>
          <FormControl component="fieldset">
            <RadioGroup aria-label="gender" name="gender1" value={lang} onChange={handleChange}>
              <FormControlLabel value="ru" control={<Radio />} label={t('Russian')} />
              <FormControlLabel value="en" control={<Radio />} label={t('English')} />
            </RadioGroup>
          </FormControl>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={onSaveLanguage} color="primary" variant="contained">
            {t('Save')}
          </Button>
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={notice.severity}>
            {notice.message}
          </Alert>
        </Snackbar>
      </Card>
    </form>
  );
};


export default Language;
