import React from 'react';
import ProductCategories from './modules/ProductCategories';
import ProductSmokingHero from './modules/ProductSmokingHero';
import AppFooter from './modules/AppFooter';
import ProductHero from './modules/ProductHero';
import ProductValues from './modules/ProductValues';
import ProductHowItWorks from './modules/ProductHowItWorks';
import ProductCTA from './modules/ProductCTA';

const LandingPage = () => {
  return (
    <React.Fragment>
      <ProductHero />
      <ProductHowItWorks />
      <ProductCategories />
      <ProductValues />
      <ProductCTA />
      <ProductSmokingHero />
      <AppFooter />
    </React.Fragment>
  );
}

export default LandingPage;
