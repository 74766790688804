import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Button,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import ProductCard from './ProductCard';
import apiService from "../../../services/apiService";
import helpers from "../../../common/helpers";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from '@material-ui/icons/Delete';
import ResultNotice from "../../../components/ResultNotice";
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const ProductList = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [pairs, setPairs] = useState([]);
  const [openNotice, setOpenNotice] = useState(false);
  const [noticeInfo, setNoticeInfo] = useState({messageNotice: 'Success', severityNotice: 'success'});
  const {
    getProductOfCurrentUser,
    getProductGalleryOfCurrentUser,
    getProductOfPartnerUser,
    getProductGalleryOfPartnerUser,
    getUserFromProduct} = helpers;
  const {closeUserPair, getUserActivePairs, deleteUserPair } = apiService;
  const { t } = useTranslation();


  useEffect( () => {
    let cleanup = false;
    const fetchData = async () => {
      const response = await getUserActivePairs();
      if(!cleanup){
        setPairs(response.data)
      }
    };
    fetchData();
    return () => cleanup = true;
  },[]);

  const onChangeHandler = async (event) => {
    const id = event.currentTarget.getAttribute('pair')
    const result = await closeUserPair(id)
    if (result.status === 200 ) {
      setPairs(result.data)
    }
  }

  const handleJumpToChat = () => {
    return navigate(`/app/chat/dialog/`, { state: '/' });
  };

  const handleRemovePair = async (event) => {
    const result = await deleteUserPair(event.currentTarget.getAttribute('pair'))
    if(result.status !== 200){
      setNoticeInfo({messageNotice: 'Somethings went wrong', severityNotice: 'error'})
      setOpenNotice(true);
    }else {
      setNoticeInfo({messageNotice: 'Item was delete successfully', severityNotice: 'success'})
      setOpenNotice(true);
      setPairs(result.data)
    }
  };
  const handleCloseNotice = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotice(false);
  };
  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth={false}>
        <Box mt={3}>
            {pairs.map((product) => (
              <Grid container spacing={3} key={product._id} justify="space-between" alignItems="center">
                <Grid item >
                  <ProductCard
                    className={classes.productCard}
                    product={getProductOfCurrentUser(product)}
                    user={getUserFromProduct(getProductOfCurrentUser(product))}
                    imageGallery={getProductGalleryOfCurrentUser(product)}/>
                </Grid>
                <Grid item>
                <Button
                  onClick={onChangeHandler}
                  user={getUserFromProduct(getProductOfCurrentUser(product))}
                  partner={getUserFromProduct(getProductOfPartnerUser(product))}
                  pair={product._id}
                  className={classes.button}
                  variant="contained"
                  color="primary">
                  {t('Close the deal')}
                </Button>
                  <Divider/>
                <Button
                  onClick={()=>handleJumpToChat()}
                  user={getUserFromProduct(getProductOfCurrentUser(product))}
                  partner={getUserFromProduct(getProductOfPartnerUser(product))}
                  pair={product._id}
                  className={classes.button}
                  variant="contained"
                  color="primary">
                  {t('Jump to chat')}
                </Button>
                  <Divider/>
                <Button
                  variant="contained"
                  color="default"
                  pair={product._id}
                  onClick={handleRemovePair}
                  className={classes.button}
                  startIcon={<DeleteIcon />} >
                  {t('Delete')}
                </Button>
                </Grid>
                <Grid item >
                  <ProductCard
                    className={classes.productCard}
                    user={getUserFromProduct(getProductOfCurrentUser(product))}
                    product={getProductOfPartnerUser(product)}
                    imageGallery={getProductGalleryOfPartnerUser(product)} />
                </Grid>
              </Grid>
            ))}
          <ResultNotice
            openNotice={openNotice}
            handleCloseNotice={handleCloseNotice}
            severityNotice={noticeInfo.severityNotice}
            messageNotice={noticeInfo.messageNotice}
          />
        </Box>
        <Box mt={3} display="flex" justifyContent="center">
          <Pagination color="primary" count={3} size="small"/>
        </Box>
      </Container>
    </Page>
  );
};

export default ProductList;
