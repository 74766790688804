import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  }
}));

const TotalChanging = ({ className, statistic, ...rest }) => {
  const classes = useStyles();
  const {totalChanging} = statistic;
  const { t } = useTranslation();

  return (
    <Card className={clsx(classes.root, className)}{...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
               {t('TOTAL CHANGING')}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {totalChanging}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalChanging.propTypes = {
  className: PropTypes.string
};

export default TotalChanging;
