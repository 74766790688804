module.exports = {
  // API_BASE_URL: 'http://localhost:4000',
  // IMAGES_URL: 'http://localhost:4000/public/img/',

  API_BASE_URL: 'https://changing-app.herokuapp.com',
  IMAGES_URL: 'https://changing-app.herokuapp.com/public/img/',
  PRODUCT_CATEGORIES: [
    'auto and transport',
    'appliances',
    'computer equipment',
    'phones and tablets',
    'electronics',
    "women's Wardrobe",
    "men's Wardrobe",
    'for children and mothers',
    'furniture',
    'repair and construction',
    'garden',
    'hobbies, sports and tourism',
    'various'
  ]
};
