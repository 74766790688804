import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import UserInfoContext from "../../../common/userInfoContext";
import helpers from "../../../common/helpers";
import apiService from "../../../services/apiService";
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Notifications = ({ className, ...rest }) => {
  const classes = useStyles();
  const { setUserInfo } = helpers;
  const { updateUserSettings } = apiService;
  const[userInfo, setUserInfoContext] = useContext(UserInfoContext);
  const { t } = useTranslation();

  const { messageNotification, pairNotification, systemNotification } = userInfo.settings;

  const setSystem = (value) => {
    const newInfo = {
      ...userInfo,
      settings: {
        ...userInfo.settings,
        systemNotification: value
      }
    };
    setUserInfoContext(newInfo);
    setUserInfo(newInfo);
  };

  const setPair = (value) => {
    const newInfo = {
      ...userInfo,
      settings: {
        ...userInfo.settings,
        pairNotification: value
      }
    };
    setUserInfoContext(newInfo);
    setUserInfo(newInfo);
  };

  const setChat = (value) => {
    const newInfo = {
      ...userInfo,
      settings: {
        ...userInfo.settings,
        messageNotification: value
      }
    };
    setUserInfoContext(newInfo);
    setUserInfo(newInfo);
  };

  const onHandleChange = (event) => {
    switch (event.target.name) {
      case 'system':
        setSystem(event.target.checked);
        break;
      case 'pair':
        setPair(event.target.checked);
        break;
      default:
        setChat(event.target.checked);
    }
  };

  const onHandleSaveSettings = async () => {
    const userData = await updateUserSettings(userInfo);
    setUserInfoContext(userData.data);
    setUserInfo(userData.data);
  };

  return (
    <form className={clsx(classes.root, className)}{...rest}>
      <Card>
        <CardHeader subheader={t("Manage the notifications")} title={t("Notifications")}/>
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item md={4} sm={6} xs={12}>
              <Typography color="textPrimary" gutterBottom variant="h6">
                {t("Notifications and Messages")}
              </Typography>
              <FormControlLabel control={(<Checkbox name={'system'} checked={systemNotification} onChange={onHandleChange} />)} label={t("System Notifications")}/>
              <FormControlLabel control={(<Checkbox name={'pair'}  checked={pairNotification} onChange={onHandleChange} />)} label={t("Pair Notifications")}/>
              <FormControlLabel control={(<Checkbox name={'chat'} checked={messageNotification} onChange={onHandleChange} />)} label={t("Chat Messages")}/>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={onHandleSaveSettings} color="primary" variant="contained">
            {t("Save")}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

Notifications.propTypes = {
  className: PropTypes.string
};

export default Notifications;
