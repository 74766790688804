import React, { useContext } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import apiService from '../../../services/apiService';
import UserInfoContext from '../../../common/userInfoContext';
import helpers from '../../../common/helpers';
import {ErrorBoundary} from 'react-error-boundary'
import ErrorFallback from "../../../components/ErrorFallback";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const { uploadAvatar, updateUserProfile } = apiService;
  const { setUserInfo } = helpers;
  const [userInfo, setUserInfoContext] = useContext(UserInfoContext);

  const handleOnSaveAvatar = async (files) => {
    const userData = await uploadAvatar(files);
    setUserInfoContext(userData.data);
    setUserInfo(userData.data);
  };

  const handleOnSaveProfileDetail = async (values) => {
    const userData = await updateUserProfile(values);
    setUserInfoContext(userData.data);
    setUserInfo(userData.data);
  };

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Profile profileInfo={userInfo} handleOnSaveAvatar={handleOnSaveAvatar} />
            </ErrorBoundary>
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ProfileDetails userInfo={userInfo} handleOnSaveProfileDetail={handleOnSaveProfileDetail} />
            </ErrorBoundary>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
